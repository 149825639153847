import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  adventCalendarActions,
  fetchAdventCalendarCopy,
  fetchAdventCalendarCsvDownload,
  fetchAdventCalendarCsvFavoriteItem,
  fetchAdventCalendarCsvFavoriteItemCreate,
  fetchAdventCalendarDelete,
  fetchAdventCalendarDetail,
  fetchAdventCalendarReference,
  fetchAdventCalendarSearch,
  fetchAdventCalendarSummary,
} from '../../slices/adventCalendarSlice';
import React, { useEffect, useRef } from 'react';
import { adventCalendarColumns } from '../../constants/columns';
import ColumnTitle from '../../components/columnTitle';
import Icons from '../../constants/Icons';
import TcButton from '../../components/tcButton';
import PageTitle from '../../components/pageTitle';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';
import TcCheckbox from '../../components/tcCheckbox';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import TableTitle from '../../components/tableTitle';
import TcTable from '../../components/tcTable';
import AdventCalendarModal from '../../components/adventCalendarModal';
import { useUpdateEffect } from 'react-use';
import DeleteModal from '../../components/modal/deleteModal';
import AdventCalendarReferenceModal from '../../components/adventCalendarModal/reference';
import {
  adventCalendarFavoriteItemOptions,
  csvDownloadTypes,
  csvFavoriteItemTypes,
} from '../../constants/options';
import CsvDownloadModal from '../../components/modal/csvDownloadModal';
import AdventCalendarResultModal from '../../components/adventCalendarModal/result';

const AdventCalendar = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const {
    searchParams,
    total,
    adventCalendarList,
    deleteAdventCalendar,
    csvModalVisible,
    favoriteItem,
  } = useSelector(state => state.adventCalendar);
  const { accountInfo } = useSelector(state => state.account);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: async values => {
      const {
        display_start_date,
        display_end_date,
        advent_calendar_start_date,
        advent_calendar_end_date,
        delete_flag,
        ...rest
      } = values;

      const params = {
        ...rest,
        display_start_date: dateFormat(display_start_date, 'YYYY/MM/DD'),
        display_end_date: dateFormat(display_end_date, 'YYYY/MM/DD'),
        advent_calendar_start_date: dateFormat(
          advent_calendar_start_date,
          'YYYY/MM/DD'
        ),
        advent_calendar_end_date: dateFormat(
          advent_calendar_end_date,
          'YYYY/MM/DD'
        ),
        delete_flag: delete_flag,
        show_deleted: delete_flag === 1,
      };

      dispatch(adventCalendarActions.saveSearchParams(params));
    },
  });

  const csvFormik = useFormik({
    initialValues: {
      checkedList: [],
      advent_calendar_code: null,
    },
    onSubmit: values =>
      dispatch(
        fetchAdventCalendarCsvDownload({
          advent_calendar_code: values?.advent_calendar_code,
          screen_type: csvDownloadTypes['advent_calendar'],
          columns: values.checkedList,
        })
      ),
  });

  useUpdateEffect(() => {
    dispatch(fetchAdventCalendarSearch(searchParams));
  }, [searchParams]);

  useEffect(() => {
    dispatch(
      fetchAdventCalendarCsvFavoriteItem({
        screen_type: csvFavoriteItemTypes['advent_calendar'],
      })
    ) && dispatch(adventCalendarActions.saveSearchParams(null));
    return () => dispatch(adventCalendarActions.clear());
  }, [dispatch]);

  const columns = [
    ...adventCalendarColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort =>
            dispatch(adventCalendarActions.saveSearchParams({ sort }))
          }
        />
      ),
      dataIndex: col.dataIndex ?? col.sortField,
      render: col.render,
    })),
    {
      title: '結果',
      align: 'center',
      width: '150px',
      render: current => (
        <div className="summary-area">
          <div className="summary-item-area">
            <TcButton
              text={'結果表示'}
              theme={'white'}
              icon={<Icons.IconResult className="btn-icon" />}
              style={{
                width: 100,
                margin: '0 auto',
                marginBottom: 10,
              }}
              onClick={() => dispatch(fetchAdventCalendarSummary(current))}
            />
          </div>
          <TcButton
            text={'CSV出力'}
            theme={'white'}
            icon={<Icons.IconDownload className="btn-icon" />}
            style={{
              width: 100,
              margin: '0 auto',
            }}
            onClick={() => {
              csvFormik.setFieldValue(
                'advent_calendar_code',
                current?.advent_calendar_code
              );
              dispatch(adventCalendarActions.openCsvDownloadModal());
            }}
          />
        </div>
      ),
    },
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current =>
        current?.delete_flag === 0 ? (
          <Icons.IconEdit
            className="table-cell-icon tc-action-icon"
            onClick={() => {
              dispatch(fetchAdventCalendarDetail(current));
            }}
          />
        ) : (
          <Icons.IconEditGy className="table-cell-icon tc-disabled-icon" />
        ),
    },
    {
      title: '複製',
      align: 'center',
      width: '80px',
      render: current => (
        <Icons.IconCopy
          className="tc-action-icon"
          height={'20px'}
          width={'20px'}
          onClick={() => {
            dispatch(
              fetchAdventCalendarCopy({
                operator_code: accountInfo.staff_code,
                advent_calendar_code: current.advent_calendar_code,
              })
            );
          }}
        />
      ),
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: current =>
        current?.delete_flag === 0 ? (
          <Icons.IconTrash
            className="table-cell-icon tc-action-icon"
            onClick={() =>
              dispatch(adventCalendarActions.openDeleteModal(current))
            }
          />
        ) : (
          <Icons.IconSearch
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchAdventCalendarReference(current))}
          />
        ),
    },
  ];

  return (
    <div className="content-body management-page advent-calendar-list-page" ref={parent}>
      <PageTitle
        title="新規登録・アドベントカレンダー一覧"
        icon={<Icons.IconAdventCalendar />}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={1098}>
          <TextBox
            formik={formik}
            label="アドベントカレンダーCD"
            fieldName="advent_calendar_code"
          />
          <TextBox
            formik={formik}
            label="アドベントカレンダー名"
            fieldName="advent_calendar_name"
          />
          <DateTimeRangePicker
            formik={formik}
            label="キャンペーン表示開始日"
            fromFieldName="display_start_date"
            toFieldName="display_end_date"
          />
          <DateTimeRangePicker
            formik={formik}
            label="アドベントカレンダー実施開始日"
            fromFieldName="advent_calendar_start_date"
            toFieldName="advent_calendar_end_date"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={!!formik.values['delete_flag']}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'delete_flag',
                !!formik.values['delete_flag'] ? 0 : 1
              )
            }
          />
        </div>
      </div>

      <TableTitle
        searchCount={total}
        onClickAdd={() => dispatch(adventCalendarActions.openEditModal(null))}
      />

      <TcTable
        columns={columns}
        dataSource={adventCalendarList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(adventCalendarActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />

      <AdventCalendarModal />
      <AdventCalendarReferenceModal />
      <AdventCalendarResultModal />

      <DeleteModal
        modalVisible={!!deleteAdventCalendar}
        onCancel={() => dispatch(adventCalendarActions.closeDeleteModal())}
        item={'アドベントカレンダー'}
        itemValue={deleteAdventCalendar?.advent_calendar_code}
        onOk={() =>
          dispatch(
            fetchAdventCalendarDelete({
              operator_code: accountInfo.staff_code,
              advent_calendar_code: deleteAdventCalendar?.advent_calendar_code,
            })
          )
        }
      />

      <CsvDownloadModal
        line={2}
        modalVisible={!!csvModalVisible}
        requiredText={null}
        options={adventCalendarFavoriteItemOptions}
        favoriteItem={favoriteItem}
        width={800}
        searchArea={true}
        onFavoriteItem={checkedList => {
          const initial = adventCalendarFavoriteItemOptions.reduce(
            (acc, item) => ({
              ...acc,
              favorite: { ...acc.favorite, [item.value]: '0' },
            }),
            {
              screen_type: csvFavoriteItemTypes['advent_calendar'],
              favorite: {},
            }
          );

          const param = checkedList.reduce(
            (acc, item) => ({
              ...acc,
              favorite: { ...acc.favorite, [item]: '1' },
            }),
            initial
          );

          dispatch(fetchAdventCalendarCsvFavoriteItemCreate(param));
        }}
        onClose={() => dispatch(adventCalendarActions.closeCsvDownloadModal())}
        onDownload={checkedList => {
          csvFormik.setValues({ ...csvFormik.values, checkedList });
          csvFormik.handleSubmit();
        }}
      />
    </div>
  );
};

export default AdventCalendar;
