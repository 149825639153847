import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';
import axios from '../utils/axiosUtil';
import { couponTypes } from '../constants/options';
import { downloadFile } from '../utils/fnUtil';
import moment from 'moment/moment';

const initialSearchParams = {
  advent_calendar_code: '',
  advent_calendar_name: '',
  display_start_date: null,
  display_end_date: null,
  advent_calendar_start_date: null,
  advent_calendar_end_date: null,
  delete_flag: 0,
  offset: 0,
  count: 10,
  sort: 'advent_calendar_code_desc',
};

const initialValues = {
  advent_calendar_name: '',
  advent_calendar_text: '',
  advent_calendar_template: '',
  display_start_date: null,
  display_start_hour: null,
  display_start_minute: null,
  display_end_date: null,
  display_end_hour: null,
  display_end_minute: null,
  advent_calendar_start_date: null,
  advent_calendar_start_hour: null,
  advent_calendar_start_minute: null,
  advent_calendar_end_date: null,
  advent_calendar_end_hour: null,
  advent_calendar_end_minute: null,
  public_notice_flag: '0',
  advent_calendar_notice_flag: '0',
  advent_calendar_gifts: [],
  banner_image_url: null,
  image_url: null,
};

const initialResultValues = {
  advent_calendar_code: '',
  advent_calendar_summary_list: [],
};

const initialState = {
  searchParams: initialSearchParams,
  initialValues,
  initialResultValues,
  total: 0,
  editModalVisible: false,
  referenceModalVisible: false,
  currentAdventCalendar: null,
  deleteAdventCalendar: null,
  isCopy: false,
  templateList: [],
  couponList: [],
  adventCalendarList: [],
  csvModalVisible: false,
  favoriteItem: [],
  summaryAdventCalendar: null,
};

export const fetchAdventCalendarSearch = createAsyncThunk(
  'fetchAdventCalendarSearch',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));
    const result = await axios.get('advent_calendar/list', { params });
    dispatch(globalActions.setPrimaryLoading(false));
    return result;
  }
);

export const fetchAdventCalendarCreate = createAsyncThunk(
  'fetchAdventCalendarCreate',
  async (params, { dispatch }) => {
    await axios.post('advent_calendar/create', { ...params });
    dispatch(globalActions.showSingleModal('登録が完了しました'));
  }
);

export const fetchAdventCalendarUpdate = createAsyncThunk(
  'fetchAdventCalendarUpdate',
  async (params, { dispatch }) => {
    await axios.post('advent_calendar/update', { ...params });
    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const fetchAdventCalendarDetail = createAsyncThunk(
  'fetchAdventCalendarDetail',
  async params =>
    await axios.get('advent_calendar/detail', {
      params: { advent_calendar_code: params.advent_calendar_code },
    })
);

export const fetchAdventCalendarCopy = createAsyncThunk(
  'fetchAdventCalendarCopy',
  async params =>
    await axios.get('advent_calendar/detail', {
      params: { advent_calendar_code: params.advent_calendar_code },
    })
);

export const fetchAdventCalendarReference = createAsyncThunk(
  'fetchAdventCalendarReference',
  async params =>
    await axios.get('advent_calendar/detail', {
      params: { advent_calendar_code: params.advent_calendar_code },
    })
);

export const fetchAdventCalendarDelete = createAsyncThunk(
  'fetchAdventCalendarDelete',
  async params => await axios.post('advent_calendar/delete', { ...params })
);

export const fetchAdventCalendarCouponList = createAsyncThunk(
  'fetchAdventCalendarCouponList',
  async () =>
    await axios.get('/coupon/list', {
      params: {
        coupon_type: couponTypes['adventCalendar'],
      },
    })
);

export const fetchAdventCalendarTemplateList = createAsyncThunk(
  'fetchAdventCalendarTemplateList',
  async () =>
    await axios.get('settings/template/list', {
      params: { template_type: 6 },
    })
);

export const fetchAdventCalendarCsvDownload = createAsyncThunk(
  'fetchAdventCalendarCsvDownload',
  async params => await axios.get('csv/download', { params })
);

export const fetchAdventCalendarCsvFavoriteItem = createAsyncThunk(
  'fetchAdventCalendarCsvFavoriteItem',
  async params => axios.get('csv/favorite_item/list', { params })
);

export const fetchAdventCalendarCsvFavoriteItemCreate = createAsyncThunk(
  'fetchAdventCalendarCsvFavoriteItemCreate',
  async (params, { dispatch }) => {
    const response = await axios.post('csv/favorite_item/create', params);

    if (response?.code === '0')
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));

    return response?.params;
  }
);

export const fetchAdventCalendarSummary = createAsyncThunk(
  'fetchAdventCalendarSummary',
  async params =>
    await axios.get('advent_calendar/summary', {
      params: { advent_calendar_code: params.advent_calendar_code },
    })
);

export const adventCalendarSlice = createSlice({
  name: 'adventCalendarSlice',
  initialState,
  reducers: {
    openCsvDownloadModal: state => ({
      ...state,
      csvModalVisible: true,
    }),
    closeCsvDownloadModal: state => ({
      ...state,
      csvModalVisible: false,
    }),
    openEditModal: state => ({
      ...state,
      editModalVisible: true,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      currentAdventCalendar: null,
      isCopy: false,
    }),
    closeResultModal: state => ({
      ...state,
      summaryAdventCalendar: null,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteAdventCalendar: payload,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteAdventCalendar: false,
    }),
    closeReferenceModal: state => ({
      ...state,
      referenceModalVisible: false,
      currentAdventCalendar: null,
    }),
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        offset: payload,
      },
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchAdventCalendarSearch.fulfilled]: (state, { payload }) => {
      state.adventCalendarList = payload.item;
      state.total = payload?.total_count;
    },
    [fetchAdventCalendarCreate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      isCopy: false,
      currentAdventCalendar: null,
      searchParams: { ...state.searchParams },
    }),
    [fetchAdventCalendarDetail.fulfilled]: (state, { payload }) => {
      const { advent_calendar_text, ...rest } = payload?.item;

      return {
        ...state,
        editModalVisible: true,
        currentAdventCalendar: {
          ...rest,
          advent_calendar_text: advent_calendar_text
            ? advent_calendar_text
                .split('-----separator-----')
                .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
        },
      };
    },
    [fetchAdventCalendarCopy.fulfilled]: (state, { payload }) => {
      const { advent_calendar_text, ...rest } = payload?.item;

      return {
        ...state,
        editModalVisible: true,
        isCopy: true,
        currentAdventCalendar: {
          ...rest,
          advent_calendar_text: advent_calendar_text
            ? advent_calendar_text
                .split('-----separator-----')
                .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
        },
      };
    },
    [fetchAdventCalendarUpdate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      currentAdventCalendar: null,
      searchParams: { ...state.searchParams },
    }),
    [fetchAdventCalendarReference.fulfilled]: (state, { payload }) => {
      const { advent_calendar_text, ...rest } = payload?.item;

      return {
        ...state,
        referenceModalVisible: true,
        currentAdventCalendar: {
          ...rest,
          advent_calendar_text: advent_calendar_text
            ? advent_calendar_text
                .split('-----separator-----')
                .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
        },
      };
    },
    [fetchAdventCalendarDelete.fulfilled]: state => ({
      ...state,
      deleteAdventCalendar: null,
      searchParams: { ...state.searchParams },
    }),
    [fetchAdventCalendarTemplateList.fulfilled]: (state, { payload }) => {
      state.templateList = payload.item;
    },
    [fetchAdventCalendarCouponList.fulfilled]: (state, { payload }) => ({
      ...state,
      couponList: [
        ...payload?.item
          ?.filter(coupon => coupon?.advent_calendar_linkage_flg === '0')
          ?.map(coupon => ({
            label: coupon.coupon_name,
            value: coupon.coupon_code,
          })),
      ],
    }),
    [fetchAdventCalendarCsvDownload.fulfilled]: (state, { payload }) =>
      typeof payload === 'string' &&
      downloadFile(
        payload,
        `新規登録・アドベントカレンダー一覧_${moment().format(
          'YYYYMMDDHHmmss'
        )}.csv`
      ),
    [fetchAdventCalendarCsvFavoriteItem.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload?.item?.favorite },
    }),
    [fetchAdventCalendarCsvFavoriteItemCreate.fulfilled]: (
      state,
      { payload }
    ) => ({
      ...state,
      favoriteItem: { ...payload?.favorite },
    }),
    [fetchAdventCalendarSummary.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        summaryAdventCalendar: payload?.item,
      };
    },
  },
});

export const adventCalendarActions = adventCalendarSlice.actions;

export default adventCalendarSlice.reducer;
