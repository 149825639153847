import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Yup from '../../utils/yupUtil';
import { useFormik } from 'formik';
import Modal from 'antd/lib/modal/Modal';
import ModalTitle from '../modalTitle';
import TcButton from '../tcButton';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import BaseUpload from '../baseUpload';
import { Image } from 'antd';
import moment from 'moment';
import TcRadio from '../tcCheckbox/tcRadio';
import { flagOptions } from '../../constants/options';
import Icons from '../../constants/Icons';
import './style.scss';
import { Icon } from '@material-ui/core';
import { serialize, toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import DateTimeRangePicker from '../util/dateTimeRangePicker';
import TextBox from '../util/textBox';
import SelectBox from '../util/selectBox';
import {
  adventCalendarActions,
  fetchAdventCalendarCouponList,
  fetchAdventCalendarCreate,
  fetchAdventCalendarTemplateList,
  fetchAdventCalendarUpdate,
} from '../../slices/adventCalendarSlice';
import { globalActions } from '../../slices/globalSlice';

const AdventCalendarModal = () => {
  const dispatch = useDispatch();

  const format = (date, format) => (date ? moment(date)?.format(format) : null);

  const {
    initialValues,
    currentAdventCalendar,
    editModalVisible,
    templateList,
    couponList,
    isCopy,
  } = useSelector(state => state.adventCalendar);

  const { accountInfo } = useSelector(state => state.account);

  const isCreate = !currentAdventCalendar;
  const isUpdate = !!currentAdventCalendar?.advent_calendar_code && !isCopy;

  const yupObject = {
    advent_calendar_name: Yup.string().required().max(50),
    banner_image_url: Yup.string().nullable().required(),
    display_start_date: Yup.string()
      .nullable()
      .test(
        'display_start_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.display_start_hour &&
          testContext.parent.display_start_minute
      ),
    display_end_date: Yup.string()
      .nullable()
      .test(
        'display_end_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.display_end_hour &&
          testContext.parent.display_end_minute
      ),
    advent_calendar_start_date: Yup.string()
      .nullable()
      .test(
        'advent_calendar_start_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.advent_calendar_start_hour &&
          testContext.parent.advent_calendar_start_minute
      ),
    advent_calendar_end_date: Yup.string()
      .nullable()
      .test(
        'advent_calendar_end_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.advent_calendar_end_hour &&
          testContext.parent.advent_calendar_end_minute
      ),
    advent_calendar_gifts: Yup.array()
      .of(
        Yup.object({
          coupon_code: Yup.string()
            .nullable()
            .required('景品を選択してください'),
        })
      )
      .min(1, '景品を選択してください'),
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      advent_calendar_text: toSlateState(
        currentAdventCalendar?.advent_calendar_text
      ),
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object(yupObject),
    onSubmit: async values => {
      //日付チェック
      const isValidDate = checkAdventCalendarDate();

      if (!isValidDate) {
        return;
      }

      if (!isUpdate) {
        // 実施期間を元に配列を作成してリクエストパラメーターと比較
        const adventCalendarDates = createDefaultAdventCalendarGifts() ?? [];

        // 実施開始日と終了日から正しい、日付リスト作成
        const adventCalendarDateList = adventCalendarDates?.map(
          item => item.advent_calendar_date
        );

        // 実際にセットされた景品リストの日付から日付リスト作成
        const formikAdventCalendarGiftList = values?.advent_calendar_gifts?.map(
          item => moment(item.advent_calendar_date).format('YYYY/MM/DD')
        );

        // 配列の数と値が登録直前に変わっていないかチェック
        const areDatesEqual =
          adventCalendarDateList.length ===
            formikAdventCalendarGiftList.length &&
          adventCalendarDateList.every(
            (date, index) => date === formikAdventCalendarGiftList[index]
          );

        if (!areDatesEqual) {
          dispatch(
            globalActions.showErrorModal({
              title: 'エラー',
              message: 'アドベントカレンダー実施期間と実施日が一致していません',
            })
          );
          return;
        }
      }

      const {
        display_start_date,
        display_start_hour,
        display_start_minute,
        display_end_date,
        display_end_hour,
        display_end_minute,
        advent_calendar_start_date,
        advent_calendar_start_hour,
        advent_calendar_start_minute,
        advent_calendar_end_date,
        advent_calendar_end_hour,
        advent_calendar_end_minute,
        public_notice_flag,
        advent_calendar_notice_flag,
        advent_calendar_text,
        advent_calendar_gifts,
        ...rest
      } = values;

      const displayStartPeriod = moment(display_start_date)
        .set('h', Number(display_start_hour))
        .set('m', Number(display_start_minute));
      const displayEndPeriod = moment(display_end_date)
        .set('h', Number(display_end_hour))
        .set('m', Number(display_end_minute));

      const adventCalendarStartPeriod = moment(advent_calendar_start_date)
        .set('h', Number(advent_calendar_start_hour))
        .set('m', Number(advent_calendar_start_minute));
      const adventCalendarEndPeriod = moment(advent_calendar_end_date)
        .set('h', Number(advent_calendar_end_hour))
        .set('m', Number(advent_calendar_end_minute));

      const adventCalendarGifs = advent_calendar_gifts?.map(gift => {
        return {
          advent_calendar_date: moment(gift?.advent_calendar_date)
            .set('h', Number(0))
            .set('m', Number(0))
            .format('YYYY-MM-DD HH:mm:ss'),
          coupon_code: gift?.coupon_code,
        };
      });

      const params = {
        ...rest,
        operator_code: accountInfo.staff_code,
        display_start_date: displayStartPeriod.format('YYYY-MM-DD HH:mm:ss'),
        display_end_date: displayEndPeriod.format('YYYY-MM-DD HH:mm:ss'),
        advent_calendar_start_date: adventCalendarStartPeriod.format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        advent_calendar_end_date: adventCalendarEndPeriod.format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        public_notice_flag: parseInt(public_notice_flag),
        advent_calendar_notice_flag: parseInt(advent_calendar_notice_flag),
        advent_calendar_gifts: adventCalendarGifs,
        advent_calendar_text: advent_calendar_text
          ?.map(t => serialize(t))
          ?.map((dom, i) => ({ sort_num: `${i + 1}`, dom }))
          .map(obj => obj.dom)
          .join('-----separator-----'),
      };

      if (isUpdate) {
        dispatch(fetchAdventCalendarUpdate(params));
      } else {
        dispatch(fetchAdventCalendarCreate(params));
      }
    },
  });

  const setValues = () => {
    formik.setValues({
      ...currentAdventCalendar,
      display_start_date: format(
        currentAdventCalendar?.display_start_date,
        'YYYY/M/D HH:mm'
      ),
      display_start_hour: format(
        currentAdventCalendar?.display_start_date,
        'HH'
      ),
      display_start_minute: format(
        currentAdventCalendar?.display_start_date,
        'mm'
      ),
      display_end_date: format(
        currentAdventCalendar?.display_end_date,
        'YYYY/M/D'
      ),
      display_end_hour: format(currentAdventCalendar?.display_end_date, 'HH'),
      display_end_minute: format(currentAdventCalendar?.display_end_date, 'mm'),
      advent_calendar_start_date: format(
        currentAdventCalendar?.advent_calendar_start_date,
        'YYYY/M/D HH:mm'
      ),
      advent_calendar_start_hour: format(
        currentAdventCalendar?.advent_calendar_start_date,
        'HH'
      ),
      advent_calendar_start_minute: format(
        currentAdventCalendar?.advent_calendar_start_date,
        'mm'
      ),
      advent_calendar_end_date: format(
        currentAdventCalendar?.advent_calendar_end_date,
        'YYYY/M/D HH:mm'
      ),
      advent_calendar_end_hour: format(
        currentAdventCalendar?.advent_calendar_end_date,
        'HH'
      ),
      advent_calendar_end_minute: format(
        currentAdventCalendar?.advent_calendar_end_date,
        'mm'
      ),
      advent_calendar_text: toSlateState(
        currentAdventCalendar?.advent_calendar_text
      ),
      advent_calendar_gifts: isUpdate
        ? currentAdventCalendar?.advent_calendar_gifts?.map(gift => {
            return {
              advent_calendar_date: format(
                gift?.advent_calendar_date,
                'YYYY/M/D'
              ),
              coupon_code: gift?.coupon_name,
            };
          })
        : [],
      public_notice_flag: currentAdventCalendar?.public_notice_flag?.toString(),
      advent_calendar_notice_flag:
        currentAdventCalendar?.advent_calendar_notice_flag?.toString(),
      template: templateList?.filter(
        e =>
          e?.template_text === currentAdventCalendar?.advent_calendar_template
      )?.[0]?.template_code,
    });
  };

  const onBack = () => dispatch(adventCalendarActions.closeEditModal());

  //反映ボタンでアドベントカレンダー日をセット
  const setDefaultAdventCalendarGifts = () => {
    // クーポンリスト更新
    dispatch(fetchAdventCalendarCouponList());
    //日付チェック
    const isValidDate = checkAdventCalendarDate(); // checkAdventCalendarDate の戻り値を確認

    // 日付が無効な場合は、処理を中断
    if (!isValidDate) {
      return; // 無効な場合は早期リターン
    }

    // アドベントカレンダー景品の配列作成
    const adventCalendarDates = createDefaultAdventCalendarGifts() ?? [];

    formik.setFieldValue('advent_calendar_gifts', adventCalendarDates);
  };

  // アドベントカレンダー景品の配列作成
  const createDefaultAdventCalendarGifts = () => {
    const startDate = new Date(advent_calendar_start_date);
    const endDate = new Date(advent_calendar_end_date);

    // 日付の時間を00:00:00に設定
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    // startDateからendDateまでの日数
    const daysCount =
      Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;

    return Array.from({ length: daysCount }, (_, i) => {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      return {
        advent_calendar_date: `${date.getFullYear()}/${String(
          date.getMonth() + 1
        ).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`,
        coupon_code: null,
      };
    });
  };

  //日付チェック
  const checkAdventCalendarDate = () => {
    const {
      advent_calendar_start_date,
      advent_calendar_start_hour,
      advent_calendar_start_minute,
      advent_calendar_end_date,
      advent_calendar_end_hour,
      advent_calendar_end_minute,
      display_start_date,
      display_start_hour,
      display_start_minute,
      display_end_date,
      display_end_hour,
      display_end_minute,
    } = formik.values;

    const adventCalendarStartDate = new Date(advent_calendar_start_date);
    adventCalendarStartDate.setHours(
      advent_calendar_start_hour,
      advent_calendar_start_minute,
      0,
      0
    );

    // アドベントカレンダー実施終了日時
    const adventCalendarEndDate = new Date(advent_calendar_end_date);
    adventCalendarEndDate.setHours(
      advent_calendar_end_hour,
      advent_calendar_end_minute,
      0,
      0
    );

    // キャンペーン表示開始日時
    const displayStartDate = new Date(display_start_date);
    displayStartDate.setHours(display_start_hour, display_start_minute, 0, 0);

    // キャンペーン表示終了日時
    const displayEndDate = new Date(display_end_date);
    displayEndDate.setHours(display_end_hour, display_end_minute, 0, 0);

    // 月またぎチェック
    if (
      adventCalendarStartDate.getFullYear() !==
        adventCalendarEndDate.getFullYear() ||
      adventCalendarStartDate.getMonth() !== adventCalendarEndDate.getMonth()
    ) {
      dispatch(
        globalActions.showErrorModal({
          title: 'エラー',
          message: (
            <>
              アドベントカレンダー実施期間の開始日と終了日は
              <br />
              同じ月の日付を入力してください
            </>
          ),
        })
      );
      return false;
    }

    // キャンペーン表示期間チェック
    if (displayStartDate.getTime() >= displayEndDate.getTime()) {
      dispatch(
        globalActions.showErrorModal({
          title: 'エラー',
          message: (
            <>
              キャンペーン実施終了日時はキャンペーン実施開始日時より
              <br />
              後の日時を入力してください
            </>
          ),
        })
      );
      return false;
    }

    // // キャンペーン開始日とアドベントカレンダー実施日チェック
    if (displayStartDate.getTime() > adventCalendarStartDate.getTime()) {
      dispatch(
        globalActions.showErrorModal({
          title: 'エラー',
          message: (
            <>
              アドベントカレンダー実施開始日時はキャンペーン表示開始日時より
              <br />
              後の日時を入力してください
            </>
          ),
        })
      );
      return false;
    }

    // アドベントカレンダー実施終了日とキャンペーン終了日チェック
    if (adventCalendarEndDate.getTime() > displayEndDate.getTime()) {
      dispatch(
        globalActions.showErrorModal({
          title: 'エラー',
          message: (
            <>
              アドベントカレンダー実施終了日時はキャンペーン表示終了日時より
              <br />
              後の日時を入力してください
            </>
          ),
        })
      );
      return false;
    }

    // アドベントカレンダー実施期間チェック
    if (adventCalendarStartDate.getTime() >= adventCalendarEndDate.getTime()) {
      dispatch(
        globalActions.showErrorModal({
          title: 'エラー',
          message: (
            <>
              アドベントカレンダー実施終了日時はアドベントカレンダー実施開始日時より
              <br />
              後の日時を入力してください
            </>
          ),
        })
      );
      return false;
    }

    return true;
  };

  const {
    advent_calendar_code,
    banner_image_url,
    image_url,
    public_notice_flag,
    advent_calendar_notice_flag,
    advent_calendar_gifts,
    display_start_date,
    advent_calendar_start_date,
    advent_calendar_end_date,
  } = formik.values;

  const isAdventCalendarGifts = advent_calendar_gifts?.length !== 0;

  const hasAdventCalendarGiftsError =
    formik.errors.advent_calendar_gifts &&
    Array.isArray(formik.errors.advent_calendar_gifts) &&
    formik.errors.advent_calendar_gifts.some(
      error => error && error.coupon_code
    );

  useEffect(
    () =>
      editModalVisible
        ? currentAdventCalendar
          ? setValues()
          : formik.validateForm()
        : formik.resetForm(),
    [editModalVisible, templateList]
  );

  useEffect(
    () =>
      editModalVisible &&
      dispatch(fetchAdventCalendarTemplateList()) &&
      dispatch(fetchAdventCalendarCouponList()),
    [editModalVisible]
  );

  return (
    <Modal
      className={'advent-calendar-modal tc-modal'}
      open={editModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={`アドベントカレンダー${
            isCreate ? '登録' : isUpdate ? '編集' : '登録(複製)'
          }`}
          tip={'*は必須項目です'}
        />

        <div className="input-area advent-calendar-input-area">
          {isUpdate && (
            <div className="code-area">
              <span className={'pre-label'}>アドベントカレンダーCD</span>
              <span className={'pre-text'}>{advent_calendar_code}</span>
            </div>
          )}

          <div className="advent-calendar-image-display-area">
            <span className="image-area-error">
              {formik.touched?.banner_image_url &&
                formik.errors?.banner_image_url}
            </span>
            <div className="image-area advent-calendar-image-area">
              <span className="pre-label label-required">バナー画像</span>
              <div className="image-select">
                {!!banner_image_url ? (
                  <>
                    <Icon
                      component={Icons.IconXCircleWh}
                      className="close-icon"
                      onClick={() => {
                        handlerFormikFieldChange(
                          formik,
                          'banner_image_url',
                          null
                        );
                      }}
                    />
                    <Image
                      src={banner_image_url}
                      width={150}
                      height={150}
                      preview={false}
                    />
                  </>
                ) : (
                  <BaseUpload
                    onChange={url =>
                      handlerFormikFieldChange(formik, 'banner_image_url', url)
                    }
                  >
                    <TcButton text="画像を追加" theme="white" />
                  </BaseUpload>
                )}
              </div>
            </div>
          </div>

          <div
            className="advent-calendar-display-area"
            style={{ marginTop: 30 }}
          >
            <div className="image-area advent-calendar-image-area">
              <span className="pre-label">背景画像</span>
              <div className="image-select">
                {!!image_url ? (
                  <>
                    <Icon
                      component={Icons.IconXCircleWh}
                      className="close-icon"
                      onClick={() => {
                        handlerFormikFieldChange(formik, 'image_url', null);
                      }}
                    />
                    <Image
                      src={image_url}
                      width={150}
                      height={150}
                      preview={false}
                    />
                  </>
                ) : (
                  <BaseUpload
                    onChange={url =>
                      handlerFormikFieldChange(formik, 'image_url', url)
                    }
                  >
                    <TcButton text="画像を追加" theme="white" />
                  </BaseUpload>
                )}
              </div>
            </div>
          </div>

          <div className="advent-calendar-modal_new_input_area">
            <TextBox
              isRequired={true}
              formik={formik}
              label="アドベントカレンダー名"
              fieldName="advent_calendar_name"
              placeholder="アドベントカレンダー名を入力してください"
              width={'100%'}
            />
          </div>

          <span
            className="cus-input-line input-textarea"
            style={{ height: 'unset' }}
          >
            <label className="cus-pre-label" style={{ minWidth: '150px' }}>
              アドベントカレンダー説明
            </label>
            <div style={{ width: '100%' }}>
              <RichTextEditor
                contentImageBlock={true}
                extraUpload={true}
                editorState={formik.values?.advent_calendar_text}
                error={
                  formik.touched.advent_calendar_text &&
                  formik.errors.advent_calendar_text
                }
                onChange={state =>
                  formik.setFieldValue('advent_calendar_text', state)
                }
                setTouched={() =>
                  !formik.touched.advent_calendar_text &&
                  formik.setFieldTouched('advent_calendar_text', true)
                }
                disabled={false}
              />
            </div>
          </span>

          <div className="advent-calendar-modal_new_input_area">
            <SelectBox
              formik={formik}
              label="定型文"
              fieldName={`template`}
              placeholder="テンプレートを選択"
              options={templateList?.map(e => ({
                value: e.template_code,
                label: e.template_title,
              }))}
              width={'100%'}
              extraOnChange={(_, v) =>
                handlerFormikFieldChange(
                  formik,
                  'advent_calendar_template',
                  templateList?.filter(e => e.template_code === v)?.[0]
                    ?.template_text
                )
              }
            />
          </div>

          <span
            className="cus-input-line input-textarea input-fixed-phrase"
            style={{ marginTop: '10px', marginBottom: 0 }}
          >
            <label className="cus-pre-label" style={{ minWidth: '150px' }} />
            <textarea
              placeholder=""
              spellCheck={false}
              autoComplete={'false'}
              value={formik.values.advent_calendar_template}
              onChange={e =>
                handlerFormikFieldChange(
                  formik,
                  'advent_calendar_template',
                  e.target.value
                )
              }
            />
          </span>

          {isUpdate ? (
            <div className={'update_date-area'} style={{ marginBottom: 38 }}>
              <span className={'pre-label'}>キャンペーン表示期間</span>
              <div className={'date-display'}>{`${display_start_date} 〜`}</div>
              <DateTimeRangePicker
                formik={formik}
                fromFieldName={'display_end_date'}
                fromHourFieldName={'display_end_hour'}
                fromMinuteFieldName={'display_end_minute'}
                width={'100%'}
                className={'advent-calendar-modal_date-area'}
              />
            </div>
          ) : (
            <div className="date-area">
              <DateTimeRangePicker
                formik={formik}
                preLabel={'キャンペーン表示期間'}
                fromFieldName={'display_start_date'}
                fromHourFieldName={'display_start_hour'}
                fromMinuteFieldName={'display_start_minute'}
                toFieldName={'display_end_date'}
                toHourFieldName={'display_end_hour'}
                toMinuteFieldName={'display_end_minute'}
                isRequired
                width={'100%'}
                className={'advent-calendar-modal_date-area'}
              />
            </div>
          )}

          {isUpdate ? (
            <div className={'update_date-area'} style={{ marginBottom: 10 }}>
              <div className={'pre-label-area'}>
                <div className="advent-calendar-label">
                  <span>
                    アドベントカレンダー
                    <br />
                    実施期間
                  </span>
                </div>
                <span className="required-mark">*</span>
              </div>
              <div className={'date-from-to-display'}>
                {`${advent_calendar_start_date} 〜 ${advent_calendar_end_date}`}
              </div>
            </div>
          ) : (
            <div className="date-area-with-btn">
              <DateTimeRangePicker
                formik={formik}
                preLabel={
                  <div>
                    アドベントカレンダー<span className="required-mark">*</span>
                    <br />
                    実施期間
                  </div>
                }
                fromFieldName="advent_calendar_start_date"
                fromHourFieldName={'advent_calendar_start_hour'}
                fromMinuteFieldName={'advent_calendar_start_minute'}
                toFieldName="advent_calendar_end_date"
                toHourFieldName={'advent_calendar_end_hour'}
                toMinuteFieldName={'advent_calendar_end_minute'}
                width={'auto'}
                className={'advent-calendar-modal_date-range-area'}
              />
              <TcButton
                text={'反映'}
                onClick={setDefaultAdventCalendarGifts}
                disabled={
                  !(
                    formik.values?.advent_calendar_start_date &&
                    formik.values?.advent_calendar_start_hour &&
                    formik.values?.advent_calendar_start_minute &&
                    formik.values?.advent_calendar_end_date &&
                    formik.values?.advent_calendar_end_hour &&
                    formik.values?.advent_calendar_end_minute
                  )
                }
              />
            </div>
          )}

          {isUpdate ? (
            <div className={'update_date-gift-area'}>
              <span className={'pre-label label-required'}>景品</span>
              <div className={'update_date-gift'}>
                {advent_calendar_gifts?.map(gift => {
                  return (
                    <div className={'update_date-gift-info'}>
                      <span style={{ width: 100 }}>
                        {gift?.advent_calendar_date}
                      </span>
                      <span>{gift?.coupon_code}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="advent-calendar-date-area">
              {formik.errors?.advent_calendar_gifts &&
                formik.touched?.advent_calendar_gifts &&
                hasAdventCalendarGiftsError && (
                  <div className={'advent_calendar_error'}>
                    景品を選択してください
                  </div>
                )}
              <div
                className={`advent-calendar-date-area-label${
                  !isAdventCalendarGifts ? ' is-disabled-label' : ''
                }`}
              >
                <label>景品</label>
              </div>
              <div className="advent-calendar-gift-area">
                {isAdventCalendarGifts ? (
                  advent_calendar_gifts?.map((gift, index) => (
                    <SelectBox
                      formik={formik}
                      label={`${gift?.advent_calendar_date}`}
                      listFieldName={`advent_calendar_gifts.[${index}].coupon_code`}
                      placeholder="クーポンを選択"
                      options={
                        couponList
                          ?.filter(coupon => {
                            // 現在の index 以外の advent_calendar_gifts の coupon_code を取得
                            const usedCoupons = advent_calendar_gifts
                              ?.filter((_, i) => i !== index) // 自分以外のギフトをフィルタ
                              ?.map(gift => gift?.coupon_code) // coupon_code だけを取得
                              ?.filter(Boolean); // null/undefined の coupon_code を除外

                            // 既に使用されているクーポンコードを除外
                            return !usedCoupons?.includes(coupon?.value);
                          })
                          ?.map(e => ({
                            value: e.value,
                            label: e.label,
                          })) || [] // couponList が null/undefined の場合は空配列を返す
                      }
                      width={'75%'}
                    />
                  ))
                ) : (
                  <SelectBox
                    formik={formik}
                    label={`YYYY/MM/DD`}
                    fieldName={`advent_calendar_gifts.[0].coupon_code`}
                    placeholder="クーポンを選択してください"
                    width={'75%'}
                    disabled={true}
                  />
                )}
              </div>
            </div>
          )}

          {isUpdate ? (
            <div className={'update_date-push-area'}>
              <span className={'pre-label label-required'}>プッシュ通知</span>
              <div className={'update_date-push-info'}>
                <div>
                  <span>キャンペーン表示開始</span>
                  <span>{public_notice_flag === '0' ? 'しない' : 'する'}</span>
                </div>
                <div>
                  <span>実施開始</span>
                  <span>
                    {advent_calendar_notice_flag === '0' ? 'しない' : 'する'}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="advent-calendar-push-area">
              <span className="pre-label label-required">プッシュ通知</span>
              <div className="advent-calendar-push-btn-area">
                <span className="cus-input-line input-checkbox">
                  <TcRadio
                    formik={formik}
                    label={'キャンペーン表示開始'}
                    isRadio={true}
                    fieldName="public_notice_flag"
                    width={150}
                    options={flagOptions}
                    onChange={v =>
                      handlerFormikFieldChange(formik, 'public_notice_flag', v)
                    }
                  />
                </span>
                <span className="cus-input-line input-checkbox">
                  <TcRadio
                    formik={formik}
                    label={'実施開始'}
                    isRadio={true}
                    fieldName="advent_calendar_notice_flag"
                    width={150}
                    options={flagOptions}
                    onChange={v =>
                      handlerFormikFieldChange(
                        formik,
                        'advent_calendar_notice_flag',
                        v
                      )
                    }
                  />
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="button-container">
          <TcButton text="戻る" theme="white" onClick={onBack} />
          <TcButton
            text={isUpdate ? '更新' : '登録'}
            onClick={formik.handleSubmit}
            disabled={!formik.isValid}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AdventCalendarModal;
