import React, { useEffect, useRef, useState } from 'react';
import PageTitle from '../../components/pageTitle';
import Icons from '../../constants/Icons';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import TcButton from '../../components/tcButton';
import TcCheckbox from '../../components/tcCheckbox';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import { categoryColumns } from '../../constants/columns';
import ColumnTitle from '../../components/columnTitle';
import TcTable from '../../components/tcTable';
import TableTitle from '../../components/tableTitle';
import {
  categoryActions,
  fetchCategoryDelete,
  fetchCategoryDetail,
  fetchCategoryList,
  fetchCategoryReference,
} from '../../slices/categorySlice';
import CategoryModal from '../../components/categoryModal';
import DeleteModal from '../../components/modal/deleteModal';
import CategoryReferenceModal from '../../components/categoryModal/reference';
import './style.scss';
import { useUpdateEffect } from 'react-use';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';

const Category = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { searchParams, categoryList, total, deleteCategory } =
    useSelector(state => state.category);

  const { selectOptionList } = useSelector(state => state.name);

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values =>
      dispatch(categoryActions.saveSearchParams(values)),
  });

  useUpdateEffect(
    () => dispatch(fetchCategoryList(searchParams)),
    [searchParams]
  );

  useEffect(() => {
    dispatch(categoryActions.saveSearchParams(null));
    return () => dispatch(categoryActions.clear());
  }, [dispatch]);

  const columns = [
    ...categoryColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort => {
            dispatch(categoryActions.saveSearchParams({ sort }));
          }}
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
    })),
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current =>
        !current.deleted_flg ? (
          <Icons.IconEdit
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchCategoryDetail(current))}
          />
        ) : (
          <Icons.IconEditGy
            className="tc-disabled-icon"
            height={'20px'}
            width={'20px'}
          />
        ),
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: current =>
        !current.deleted_flg ? (
          <Icons.IconTrash
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => {
              dispatch(categoryActions.openDeleteModal(current));
            }}
          />
        ) : (
          <Icons.IconSearch
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchCategoryReference(current))}
          />
        ),
    },
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle title="カテゴリ管理" icon={<Icons.IconSettings />} />
      <div className="search-form">
        <InputContainer parent={parent} max={1472}>
          <TextBox
            formik={formik}
            label="カテゴリCD"
            fieldName="category_code"
          />
          <SelectBox
            formik={formik}
            label="カテゴリ種別"
            fieldName="category_type"
            options={
              selectOptionList?.filter(
                s => s.field_name === 'カテゴリ種別'
              )?.[0]?.select_option ?? []
            }
          />
          <TextBox
            formik={formik}
            label="カテゴリ名"
            fieldName="category_name"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={!!formik.values['deleted_flg']}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'deleted_flg',
                !!formik.values['deleted_flg'] ? 0 : 1
              )
            }
          />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickAdd={() => dispatch(categoryActions.openEditModal(null))}
      />
      <TcTable
        columns={columns}
        dataSource={categoryList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(categoryActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <CategoryModal />
      <CategoryReferenceModal />
      <DeleteModal
        modalVisible={!!deleteCategory}
        onCancel={() => dispatch(categoryActions.closeDeleteModal())}
        item={'カテゴリ'}
        itemValue={deleteCategory?.category_code}
        onOk={() =>
          dispatch(
            fetchCategoryDelete({
              category_code: deleteCategory?.category_code,
            })
          )
        }
      />
    </div>
  );
};

export default Category;
