import { ReactComponent as icon_active_user } from '../images/icon_active_user.svg';
import { ReactComponent as icon_bar_chart } from '../images/icon_bar-chart.svg';
import { ReactComponent as icon_bell } from '../images/icon_bell.svg';
import { ReactComponent as icon_bookmark } from '../images/icon_bookmark.svg';
import { ReactComponent as icon_bookmark_gy } from '../images/icon_bookmark_gy.svg';
import { ReactComponent as icon_calendar } from '../images/icon_calendar.svg';
import { ReactComponent as icon_calendar_gy } from '../images/icon_calendar_gy.svg';
import { ReactComponent as icon_chart } from '../images/icon_chart.svg';
import { ReactComponent as icon_check } from '../images/icon_check.svg';
import { ReactComponent as icon_check_list } from '../images/icon_check_list.svg';
import { ReactComponent as icon_check_in } from '../images/icon_checkin.svg';
import { ReactComponent as icon_chevron_down } from '../images/icon_chevron-down.svg';
import { ReactComponent as icon_chevron_down_gy } from '../images/icon_chevron-down_gy.svg';
import { ReactComponent as icon_chevron_left } from '../images/icon_chevron-left.svg';
import { ReactComponent as icon_chevron_left_gy } from '../images/icon_chevron-left_gy.svg';
import { ReactComponent as icon_chevron_right } from '../images/icon_chevron-right.svg';
import { ReactComponent as icon_chevron_right_gy } from '../images/icon_chevron-right_gy.svg';
import { ReactComponent as icon_chevron_up } from '../images/icon_chevron-up.svg';
import { ReactComponent as icon_chevron_up_gy } from '../images/icon_chevron-up_gy.svg';
import { ReactComponent as icon_clip } from '../images/icon_clip.svg';
import { ReactComponent as icon_clip_gy } from '../images/icon_clip_gy.svg';
import { ReactComponent as icon_clock } from '../images/icon_clock.svg';
import { ReactComponent as icon_clock_gy } from '../images/icon_clock_gy.svg';
import { ReactComponent as icon_copy } from '../images/icon_copy.svg';
import { ReactComponent as icon_contents } from '../images/icon_contents.svg';
import { ReactComponent as icon_coupon } from '../images/icon_coupon.svg';
import { ReactComponent as icon_database } from '../images/icon_database.svg';
import { ReactComponent as icon_down } from '../images/icon_down.svg';
import { ReactComponent as icon_download } from '../images/icon_download.svg';
import { ReactComponent as icon_download_gy } from '../images/icon_download_gy.svg';
import { ReactComponent as icon_edit } from '../images/icon_edit.svg';
import { ReactComponent as icon_edit_s } from '../images/icon_edit_s.svg';
import { ReactComponent as icon_edit_gy } from '../images/icon_edit_gy.svg';
import { ReactComponent as icon_enquete } from '../images/icon_questionnaire.svg';
import { ReactComponent as icon_external_link } from '../images/icon_external-link.svg';
import { ReactComponent as icon_eye } from '../images/icon_eye.svg';
import { ReactComponent as icon_eye_off } from '../images/icon_eye-off.svg';
import { ReactComponent as icon_gatya } from '../images/icon_gatya.svg';
import { ReactComponent as icon_graph } from '../images/icon_graph.svg';
import { ReactComponent as icon_help_circle } from '../images/icon_help-circle.svg';
import { ReactComponent as icon_info } from '../images/icon_info.svg';
import { ReactComponent as icon_list } from '../images/icon_list.svg';
import { ReactComponent as icon_play } from '../images/icon_play.svg';
import { ReactComponent as icon_plus } from '../images/icon_plus.svg';
import { ReactComponent as icon_plus_wh } from '../images/icon_plus_wh.svg';
import { ReactComponent as icon_result } from '../images/icon_result.svg';
import { ReactComponent as icon_result_gy } from '../images/icon_result_gy.svg';
import { ReactComponent as icon_search } from '../images/icon_search.svg';
import { ReactComponent as icon_search_gy } from '../images/icon_search_gy.svg';
import { ReactComponent as icon_settings } from '../images/icon_settings.svg';
import { ReactComponent as icon_settings_gy } from '../images/icon_settings_gy.svg';
import { ReactComponent as icon_stamp } from '../images/icon_stamp.svg';
import { ReactComponent as icon_stamp_rally } from '../images/icon_stamprally.svg';
import { ReactComponent as icon_stop } from '../images/icon_stop.svg';
import { ReactComponent as icon_trash } from '../images/icon_trash.svg';
import { ReactComponent as icon_trash_gy } from '../images/icon_trash_gy.svg';
import { ReactComponent as icon_up } from '../images/icon_up.svg';
import { ReactComponent as icon_upload } from '../images/icon_upload.svg';
import { ReactComponent as icon_upload_gy } from '../images/icon_upload_gy.svg';
import { ReactComponent as icon_vector_down } from '../images/icon_vector_down.svg';
import { ReactComponent as icon_vector_stay } from '../images/icon_vector_stay.svg';
import { ReactComponent as icon_vector_up } from '../images/icon_vector_up.svg';
import { ReactComponent as icon_x_circle } from '../images/icon_x-circle.svg';
import { ReactComponent as icon_x_circle_s } from '../images/icon_x-circle_s.svg';
import { ReactComponent as icon_x_circle_gy } from '../images/icon_x-circle_gy.svg';
import { ReactComponent as icon_x_circle_wh } from '../images/icon_x-circle_wh.svg';
import { ReactComponent as logo_tenco } from '../images/logo_tenco.svg';
import { ReactComponent as plus_icon } from '../images/icon_plus.svg';
import { ReactComponent as icon_lottery } from '../images/icon_lottery.svg';
import { ReactComponent as icon_line } from '../images/icon_LINE.svg';
import { ReactComponent as icon_advent_calendar } from '../images/icon_advent.svg';
// richTextEditor
import { ReactComponent as Icon1 } from '../images/forRichTextEditor/icon1.svg';
import { ReactComponent as Icon2 } from '../images/forRichTextEditor/icon2.svg';
import { ReactComponent as Icon3 } from '../images/forRichTextEditor/icon3.svg';
import { ReactComponent as Icon4 } from '../images/forRichTextEditor/icon4.svg';
import { ReactComponent as Icon5 } from '../images/forRichTextEditor/icon5.svg';
import { ReactComponent as Icon6 } from '../images/forRichTextEditor/icon6.svg';
import { ReactComponent as Icon7 } from '../images/forRichTextEditor/icon7.svg';
import { ReactComponent as Icon8 } from '../images/forRichTextEditor/icon8.svg';
import { ReactComponent as Icon9 } from '../images/forRichTextEditor/icon9.svg';
import { ReactComponent as Icon10 } from '../images/forRichTextEditor/icon10.svg';
import { ReactComponent as Icon11 } from '../images/forRichTextEditor/icon11.svg';
import { ReactComponent as Icon12 } from '../images/forRichTextEditor/icon12.svg';
import { ReactComponent as Icon13 } from '../images/forRichTextEditor/icon13.svg';
import { ReactComponent as Icon14 } from '../images/forRichTextEditor/icon14.svg';
import { ReactComponent as Icon15 } from '../images/forRichTextEditor/icon15.svg';
import { ReactComponent as Icon16 } from '../images/forRichTextEditor/icon16.svg';
import { ReactComponent as Icon16_2 } from '../images/forRichTextEditor/icon16_2.svg';
import { ReactComponent as Icon17 } from '../images/forRichTextEditor/icon17.svg';
import { ReactComponent as Icon18 } from '../images/forRichTextEditor/icon18.svg';
import { ReactComponent as Icon19 } from '../images/forRichTextEditor/icon19.svg';
import { ReactComponent as Icon20 } from '../images/forRichTextEditor/icon20.svg';
import { ReactComponent as Icon22 } from '../images/forRichTextEditor/icon22.svg';
import { ReactComponent as Icon23 } from '../images/forRichTextEditor/icon23.svg';
import { ReactComponent as Icon24 } from '../images/forRichTextEditor/icon24.svg';
import { ReactComponent as Icon25 } from '../images/forRichTextEditor/icon25.svg';
import { ReactComponent as Icon26 } from '../images/forRichTextEditor/icon26.svg';
import { ReactComponent as Icon27 } from '../images/forRichTextEditor/icon27.svg';
import { ReactComponent as Icon28 } from '../images/forRichTextEditor/icon28.svg';
import { ReactComponent as Icon29 } from '../images/forRichTextEditor/icon29.svg';
import { ReactComponent as Icon30 } from '../images/forRichTextEditor/icon30.svg';
import { ReactComponent as NoImgaeIcon } from '../images/forRichTextEditor/noimage.svg';
import { ReactComponent as NoImgaeSquare1 } from '../images/forRichTextEditor/noimage_square1.svg';
import { ReactComponent as NoImgaeSquare2 } from '../images/forRichTextEditor/noimage_square2.svg';
import { ReactComponent as LogoOnlineservice } from '../images/forRichTextEditor/logo_onlineservice.svg';
import { ReactComponent as Icon_PC } from '../images/forRichTextEditor/icon_pc.svg';
import { ReactComponent as Icon_SP } from '../images/forRichTextEditor/icon_sp.svg';
import { ReactComponent as Camera_OFF } from '../images/forRichTextEditor/camera_off.svg';
import { ReactComponent as Camera_ON } from '../images/forRichTextEditor/camera_on.svg';
import { ReactComponent as Mike_OFF } from '../images/forRichTextEditor/mike_off.svg';
import { ReactComponent as Mike_ON } from '../images/forRichTextEditor/mike_on.svg';
import { ReactComponent as Menu_b } from '../images/forRichTextEditor/menu_b.svg';
import { ReactComponent as Icon_Paper_Airplane_W } from '../images/forRichTextEditor/icon_paper airplane_w.svg';

const Icons = {
  IconActiveUser: icon_active_user,
  IconBarChart: icon_bar_chart,
  IconBell: icon_bell,
  IconBookmark: icon_bookmark,
  IconBookmarkGy: icon_bookmark_gy,
  IconCalendar: icon_calendar,
  IconCalendarGy: icon_calendar_gy,
  IconChart: icon_chart,
  IconCheck: icon_check,
  IconCheckIn: icon_check_in,
  IconChevronDown: icon_chevron_down,
  IconChevronDownGy: icon_chevron_down_gy,
  IconChevronLeft: icon_chevron_left,
  IconChevronLeftGy: icon_chevron_left_gy,
  IconChevronRight: icon_chevron_right,
  IconChevronRightGy: icon_chevron_right_gy,
  IconChevronUp: icon_chevron_up,
  IconChevronUpGy: icon_chevron_up_gy,
  IconClip: icon_clip,
  IconClipGy: icon_clip_gy,
  IconClock: icon_clock,
  IconClockGy: icon_clock_gy,
  IconCopy: icon_copy,
  IconContents: icon_contents,
  IconCoupon: icon_coupon,
  IconDatabase: icon_database,
  IconDown: icon_down,
  IconDownload: icon_download,
  IconDownloadGy: icon_download_gy,
  IconEdit: icon_edit,
  IconEditS: icon_edit_s,
  IconEditGy: icon_edit_gy,
  IconEnquete: icon_enquete,
  IconExternalLink: icon_external_link,
  IconEye: icon_eye,
  IconEyeOff: icon_eye_off,
  IconGatya: icon_gatya,
  IconGraph: icon_graph,
  IconHelpCircle: icon_help_circle,
  IconInfo: icon_info,
  IconList: icon_list,
  IconPlay: icon_play,
  IconPlusWh: icon_plus_wh,
  IconResult: icon_result,
  IconResultGy: icon_result_gy,
  IconSearch: icon_search,
  IconSearchGy: icon_search_gy,
  IconSettings: icon_settings,
  IconSettingsGy: icon_settings_gy,
  IconStamp: icon_stamp,
  IconStampRally: icon_stamp_rally,
  IconStop: icon_stop,
  IconTrash: icon_trash,
  IconTrashGy: icon_trash_gy,
  IconUp: icon_up,
  IconUpload: icon_upload,
  IconUploadGy: icon_upload_gy,
  IconVectorDown: icon_vector_down,
  IconVectorStay: icon_vector_stay,
  IconVectorUp: icon_vector_up,
  IconXCircle: icon_x_circle,
  IconXCircleS: icon_x_circle_s,
  IconXCircleGy: icon_x_circle_gy,
  IconXCircleWh: icon_x_circle_wh,
  LogoTenco: logo_tenco,
  IconPlus: plus_icon,
  IconCheckList: icon_check_list,
  ImageIcon1: Icon1,
  ImageIcon2: Icon2,
  ImageIcon3: Icon3,
  EditIcon: Icon4,
  RemoveIcon: Icon5,
  BoldIcon: Icon6,
  ImageIcon7: Icon7,
  ImageIcon8: Icon8,
  ImageIcon9: Icon9,
  ImageIcon10: Icon10,
  ImageIcon11: Icon11,
  ImageIcon12: Icon12,
  ImageIcon13: Icon13,
  ImageIcon14: Icon14,
  ImageIcon15: Icon15,
  ImageIcon16: Icon16,
  ImageIcon16_2: Icon16_2,
  CloseIcon: Icon17,
  ImageAddIcon: Icon18,
  FilterIcon: Icon19,
  SortAscendingIcon: Icon22,
  SortDescendingIcon: Icon20,
  FilterUsedIcon: Icon23,
  SettingIcon: Icon24,
  DisableSearchIcon: Icon25,
  SearchIcon: Icon26,
  DisableEditIcon: Icon27,
  favoriteItemIcon: Icon28,
  DisableSettingIcon: Icon29,
  DisableRemoveIcon: Icon30,
  NoImgaeIcon: NoImgaeIcon,
  NoImgaeSquare1: NoImgaeSquare1,
  NoImgaeSquare2: NoImgaeSquare2,
  LogoOnlineservice: LogoOnlineservice,
  PCIcon: Icon_PC,
  SPIcon: Icon_SP,
  CameraOFF: Camera_OFF,
  CameraON: Camera_ON,
  MikeOFF: Mike_OFF,
  MikeON: Mike_ON,
  MenuB: Menu_b,
  PaperAirplaneW: Icon_Paper_Airplane_W,
  IconLottery: icon_lottery,
  IconLine: icon_line,
  IconTemplate: icon_settings,
  IconAdventCalendar: icon_advent_calendar,
};

export default Icons;
