import { configureStore } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import accountReducer from '../slices/accountSlice';
import globalReducer from '../slices/globalSlice';
import topReducer from '../slices/topSlice';
import topImageReducer from '../slices/topImageSlice';
import contentsReducer from '../slices/contentsSlice';
import couponReducer from '../slices/couponSlice';
import couponResultReducer from '../slices/couponResultSlice';
import couponUserResultReducer from '../slices/couponUserResultSlice';
import downloadReducer from './downloadSlice';
import activeUserReducer from './activeUserSlice';
import heatmapReducer from './heatmapSlice';
import userManagementReducer from '../slices/userManagementSlice';
import brandManagementReducer from '../slices/brandManagementSlice';
import shopManagementReducer from '../slices/shopManagementSlice';
import staffManagementReducer from '../slices/staffManagementSlice';
import nameReducer from './nameSlice';
import stampReducer from '../slices/stampSlice';
import qrReducer from '../slices/qrSlice';
import mountUserResultReducer from '../slices/mountUserResultSlice';
import stampedDetailReducer from '../slices/stampedDetailSlice';
import notificationReducer from '../slices/notificationSlice';
import reserveReducer from '../slices/reserveSlice';
import geopushReducer from '../slices/geopushSlice';
import geopushInfoReducer from '../slices/geopushInfoSlice';
import categoryReducer from './categorySlice';
import enqueteReducer from './enqueteSlice';
import lotteryReducer from './lotterySlice';
import lotteryApplicantReducer from './lotteryApplicantSlice';
import stampRallyReducer from './stampRallySlice';
import stampRallyResultReducer from './stampRallyResultSlice';
import stampRallyUserResultReducer from './stampRallyUserResultSlice';
import pointExchangeResultReducer from './pointExchangeResultSlice';
import articlesReducer from '../slices/articlesSlice';
import productReducer from '../slices/productSlice';
import gachaReducer from './gachaSlice';
import gachaApplicantReducer from './gachaApplicantSlice';
import lineRichmenuReducer from './lineRichmenuSlice';
import lineAccountReducer from './lineAccountSlice';
import templateReducer from './templateSlice';
import adventCalendarReducer from "./adventCalendarSlice";

export default configureStore({
  reducer: {
    account: accountReducer,
    global: globalReducer,
    top: topReducer,
    topImage: topImageReducer,
    contents: contentsReducer,
    coupon: couponReducer,
    notification: notificationReducer,
    reserve: reserveReducer,
    geopush: geopushReducer,
    geopushInfo: geopushInfoReducer,
    couponResult: couponResultReducer,
    couponUserResult: couponUserResultReducer,
    download: downloadReducer,
    activeUser: activeUserReducer,
    heatmap: heatmapReducer,
    userManagement: userManagementReducer,
    brandManagement: brandManagementReducer,
    shopManagement: shopManagementReducer,
    staffManagement: staffManagementReducer,
    name: nameReducer,
    stamp: stampReducer,
    qr: qrReducer,
    mountUserResult: mountUserResultReducer,
    stampedDetail: stampedDetailReducer,
    enquete: enqueteReducer,
    category: categoryReducer,
    lottery: lotteryReducer,
    lotteryApplicant: lotteryApplicantReducer,
    stampRally: stampRallyReducer,
    stampRallyResult: stampRallyResultReducer,
    stampRallyUserResult: stampRallyUserResultReducer,
    pointExchangeResult: pointExchangeResultReducer,
    articles: articlesReducer,
    product: productReducer,
    gacha: gachaReducer,
    gachaApplicant: gachaApplicantReducer,
    lineRichmenu: lineRichmenuReducer,
    lineAccount: lineAccountReducer,
    template: templateReducer,
    adventCalendar: adventCalendarReducer,
  },
  preloadedState: load({ states: ['account'] }),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      save({ states: ['account'] })
    ),
});
