import ModalTitle from '../modalTitle';
import { Modal } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adventCalendarActions } from '../../slices/adventCalendarSlice';
import ReferenceItem from '../referenceItem';
import moment from 'moment/moment';
import TcButton from '../tcButton';
import './style.scss';

const AdventCalendarResultModal = () => {
  const format = (date, format) => (date ? moment(date)?.format(format) : null);
  const dispatch = useDispatch();

  const { summaryAdventCalendar, initialResultValues } = useSelector(
    state => state.adventCalendar
  );

  const { total_join_count, advent_calendar_summary_list } =
    summaryAdventCalendar || initialResultValues;

  const onBack = () => dispatch(adventCalendarActions.closeResultModal());

  return (
    <Modal
      className={'advent-calendar-result-modal tc-modal'}
      visible={!!summaryAdventCalendar}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={800}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle title="アドベントカレンダー結果" />
        <div className="input-area">
          <ReferenceItem
            label={'参加人数'}
            value={`${total_join_count?.toLocaleString()} 人`}
          />
          <div className="label-area">
            <span>実施日</span>
            <span>参加人数</span>
          </div>
          {advent_calendar_summary_list?.map(summary => (
            <ReferenceItem
              label={format(summary?.advent_calendar_date, 'YYYY/M/D')}
              value={summary?.join_count?.toLocaleString()}
            />
          ))}
        </div>

        <div className="button-container">
          <TcButton text="閉じる" theme="black" onClick={onBack} />
        </div>
      </div>
    </Modal>
  );
};

export default AdventCalendarResultModal;
