import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useFormik } from 'formik';
import {
  fetchCouponUserResultList,
  couponUserResultActions,
  fetchCouponUserResultCsvFavoriteItem,
  updateCouponUserResultCsvFavoriteItem,
  csvDownloadCouponUserResult,
} from '../../slices/couponUserResultSlice';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import {
  couponTypeOptions,
  couponUserResultFavoriteItemOptions,
} from '../../constants/options';
import { couponUserResultColumns } from '../../constants/columns';
import Icons from '../../constants/Icons';
import PageTitle from '../../components/pageTitle';
import TcButton from '../../components/tcButton';
import TcTable from '../../components/tcTable';
import ColumnTitle from '../../components/columnTitle';
import TableTitle from '../../components/tableTitle';
import CsvDownloadModal from '../../components/modal/csvDownloadModal';
import SelectColumsModal from '../../components/modal/selectCloumsModal';
import TcCheckbox from '../../components/tcCheckbox';
import moment from 'moment';
import './style.scss';
import InputContainer from '../../components/inputContainer';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';

const CouponUserResult = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const {
    accountInfo,
    accountBrandList: brandList,
    accountShopList: shopList,
  } = useSelector(state => state.account);

  const {
    searchParams,
    couponUserResultList,
    total,
    csvDownloadModalVisible,
    selectColumsVisible,
    favoriteItem,
    showFields,
  } = useSelector(state => state.couponUserResult);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => {
      const {
        from_delivery_date,
        to_delivery_date,
        from_used_date,
        to_used_date,
        from_expire_date,
        to_expire_date,
        ...rest
      } = values;
      const params = {
        ...rest,
        from_delivery_date: dateFormat(from_delivery_date, 'YYYY/MM/DD'),
        to_delivery_date: dateFormat(to_delivery_date, 'YYYY/MM/DD'),
        from_used_date: dateFormat(from_used_date, 'YYYY/MM/DD'),
        to_used_date: dateFormat(to_used_date, 'YYYY/MM/DD'),
        from_expire_date: dateFormat(from_expire_date, 'YYYY/MM/DD'),
        to_expire_date: dateFormat(to_expire_date, 'YYYY/MM/DD'),
      };

      dispatch(couponUserResultActions.saveSearchParams(params));
    },
  });

  const {
    brands,
    csv_coupon_type,
    csv_brands,
    csv_shops,
    csv_from_delivery_date,
    csv_to_delivery_date,
  } = formik.values;

  const filterCsvShopList = shopList.filter(
    s =>
      csv_brands.filter(b => b).length === 0 ||
      csv_brands.includes(s.brand_code)
  );

  useEffect(() => {
    dispatch(fetchCouponUserResultCsvFavoriteItem());
    dispatch(couponUserResultActions.saveSearchParams(null));
    dispatch(
      couponUserResultActions.saveShowFields(
        columns.filter(
          c =>
            !couponUserResultColumns.filter(d => c.key === d.sortField)[0]
              .defaultHiddenFiled
        )
      )
    );
    return () => dispatch(couponUserResultActions.clear());
  }, [dispatch]);

  useUpdateEffect(() => {
    dispatch(fetchCouponUserResultList(searchParams));
  }, [searchParams]);

  const columns = [
    ...couponUserResultColumns.map(col => ({
      key: col.sortField,
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort =>
            dispatch(couponUserResultActions.saveSearchParams({ sort }))
          }
        />
      ),
      dataIndex: col.sortField,
      render:
        col.sortField === 'open_date' && accountInfo.coupon_opend_count_mask
          ? () => '-'
          : null,
    })),
  ];

  const displayColumnOptions = [
    ...couponUserResultColumns.map(e => ({
      label: e.title,
      value: e.sortField,
    })),
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle
        title="ユーザー×クーポン単位実績一覧"
        icon={<Icons.IconCoupon className="coupon-icon" />}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={1472}>
          <TextBox formik={formik} label="会員番号" fieldName="user_code" />
          <DateTimeRangePicker
            formik={formik}
            label="配信日"
            fromFieldName="from_delivery_date"
            toFieldName="to_delivery_date"
          />
          <SelectBox
            formik={formik}
            label="ブランド"
            fieldName="brands"
            mode="multiple"
            options={brandList}
            extraOnChange={() => formik.setFieldValue('shops', [])}
          />
          <SelectBox
            formik={formik}
            label="店舗"
            fieldName="shops"
            mode="multiple"
            options={shopList.filter(
              shop => brands.length === 0 || brands.includes(shop.brand_code)
            )}
          />
          <TextBox formik={formik} label="クーポンCD" fieldName="coupon_code" />
          <TextBox formik={formik} label="クーポン名" fieldName="coupon_name" />
          <TextBox formik={formik} label="台紙CD" fieldName="couponcard_code" />
          <TextBox formik={formik} label="台紙名" fieldName="couponcard_name" />
          <DateTimeRangePicker
            formik={formik}
            label="利用日"
            fromFieldName="from_used_date"
            toFieldName="to_used_date"
          />
          <DateTimeRangePicker
            formik={formik}
            label="失効日"
            fromFieldName="from_expire_date"
            toFieldName="to_expire_date"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickCsvOutput={() => {
          handlerFormikFieldChange(
            formik,
            'csv_from_delivery_date',
            csv_from_delivery_date || moment().format('YYYY/M/1')
          );
          handlerFormikFieldChange(
            formik,
            'csv_to_delivery_date',
            csv_to_delivery_date || moment().format('YYYY/M/D')
          );
          dispatch(couponUserResultActions.openCsvDownloadModal());
        }}
        onClickDisplayChange={() =>
          dispatch(couponUserResultActions.openSelectColumsModal())
        }
      />
      <TcTable
        columns={columns.filter(e =>
          showFields.map(s => s.dataIndex).includes(e.dataIndex)
        )}
        dataSource={couponUserResultList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(couponUserResultActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <CsvDownloadModal
        line={5}
        width={800}
        requiredText={null}
        modalVisible={csvDownloadModalVisible}
        options={couponUserResultFavoriteItemOptions}
        favoriteItem={favoriteItem}
        disabled={!(csv_from_delivery_date && csv_to_delivery_date)}
        searchArea={
          <>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {couponTypeOptions.map(v => (
                <TcCheckbox
                  label={`${v.label}クーポン`}
                  onChange={() =>
                    handlerFormikFieldChange(formik, 'csv_coupon_type', v.value)
                  }
                  checked={csv_coupon_type === v.value}
                  className="coupon-result-csv-modal-checkbox"
                  isRadio={true}
                />
              ))}
            </div>
            <div style={{ display: 'flex', marginTop: 10 }}>
              <SelectBox
                formik={formik}
                label="ブランド"
                fieldName="csv_brands"
                disabled={csv_coupon_type === '0' || csv_coupon_type === '7'}
                mode="multiple"
                isRequired={csv_coupon_type !== '0' || csv_coupon_type !== '7'}
                options={[{ label: 'すべて', value: '' }, ...brandList]}
                width={300}
                customOnChange={(v, fieldName) => {
                  const isNoSelect = v.filter(value => value).length === 0;
                  const isLastEmpty = v[v.length - 1] === '';
                  const value =
                    isNoSelect || isLastEmpty ? [''] : v.filter(value => value);
                  handlerFormikFieldChange(formik, fieldName, value);
                  handlerFormikFieldChange(formik, 'csv_shops', ['']);
                }}
              />
              <SelectBox
                formik={formik}
                label="店舗"
                fieldName="csv_shops"
                disabled={csv_coupon_type === '0' || csv_coupon_type === '7'}
                mode="multiple"
                isRequired={csv_coupon_type !== '0' || csv_coupon_type !== '7'}
                options={[{ label: 'すべて', value: '' }, ...filterCsvShopList]}
                width={300}
                customOnChange={(v, fieldName) => {
                  const isNoSelect = v.filter(value => value).length === 0;
                  const isLastEmpty = v[v.length - 1] === '';
                  const value =
                    isNoSelect || isLastEmpty ? [''] : v.filter(value => value);
                  handlerFormikFieldChange(formik, fieldName, value);
                }}
                style={{ marginLeft: 24 }}
              />
            </div>
            <div className="csv-modal-warning">
              ※ブランド/店舗で「すべて」を選択された場合、ダウンロードに時間がかかる場合があります。
            </div>
            <DateTimeRangePicker
              formik={formik}
              label={'配信日'}
              fromFieldName="csv_from_delivery_date"
              toFieldName="csv_to_delivery_date"
              isRequired
            />
          </>
        }
        onFavoriteItem={checkedList => {
          const param = {
            screen_type: '1',
            favorite: {},
          };
          couponUserResultFavoriteItemOptions
            .filter(item => item)
            .forEach(item => (param.favorite[item.value] = '0'));
          checkedList
            .filter(item => item)
            .forEach(item => (param.favorite[item] = '1'));
          dispatch(updateCouponUserResultCsvFavoriteItem(param));
        }}
        onClose={() =>
          dispatch(couponUserResultActions.closeCsvDownloadModal())
        }
        onDownload={checkedList => {
          dispatch(
            csvDownloadCouponUserResult({
              coupon_type: csv_coupon_type,
              brands: csv_coupon_type === '0' ? [] : csv_brands.filter(v => v),
              shops: csv_coupon_type === '0' ? [] : csv_shops.filter(v => v),
              from_delivery_date: dateFormat(
                csv_from_delivery_date,
                'YYYY/MM/DD'
              ),
              to_delivery_date: dateFormat(csv_to_delivery_date, 'YYYY/MM/DD'),
              screen_type: '3',
              columns: checkedList,
            })
          );
        }}
      />
      <SelectColumsModal
        title="表示する項目を選択してください"
        modalVisible={selectColumsVisible}
        closeModal={() =>
          dispatch(couponUserResultActions.closeSelectColumsModal())
        }
        options={displayColumnOptions}
        onDisplay={checkedList => {
          dispatch(
            couponUserResultActions.saveShowFields(
              columns.filter(col =>
                checkedList.some(checkedCol => checkedCol === col.key)
              )
            )
          );
        }}
        chekedValues={couponUserResultColumns
          .filter(e => !e.defaultHiddenFiled)
          .map(e => e?.sortField)}
        initialize={false}
      />
    </div>
  );
};

export default CouponUserResult;
