import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Modal, Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useFormik } from 'formik';
import moment from 'moment';
import {
  displayOrderOptions,
  contentsTypeOptions,
  flagOptions,
  canUpdateSendStatus,
  condOptions,
  genderOptions2,
  monthOptions,
  prefectures,
} from '../../constants/options';
import {
  contentsActions,
  contentsCreate,
  contentsUpdate,
} from '../../slices/contentsSlice';
import { globalActions } from '../../slices/globalSlice';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import BaseUpload from '../baseUpload';
import ModalTitle from '../modalTitle';
import PreviewModal from '../../components/modal/previewModal';
import ContentsTooltip from '../../components/contentsTooltip';
import TcRadio from '../../components/tcCheckbox/tcRadio';
import Button from '../../components/tcButton';
import Icon from '@ant-design/icons';
import Icons from '../../constants/Icons';
import Yup from '../../utils/yupUtil';
import './style.scss';
import RichTextEditor from '../richTextEditor';
import { serialize, toSlateState } from '../richTextEditor/utils/serializer';
import DateTimeRangePicker from '../util/dateTimeRangePicker';
import SelectBox from '../util/selectBox';
import TextBox from '../util/textBox';
import CustomNumberPicker from '../util/customNumberPicker';
import PrefecturesDualListBox from '../dualListBox/prefectures';
import DualListBox from '../dualListBox';
import {
  fetchNotificationReserveFileCheck,
  notificationActions,
} from '../../slices/notificationSlice';
import BaseFileUpload from '../baseUpload/file';
import TcButton from '../../components/tcButton';

/* コンテンツ登録モーダル */

const ContentsModal = () => {
  const dispatch = useDispatch();

  const [isDetailOpen, setIsDetailOpen] = useState(true);
  const [availableShops, setAvailableShops] = useState([]);

  const { editModalVisible, currentContents } = useSelector(
    state => state.contents
  );

  const { accountInfo, accountBrandList, accountShopList } = useSelector(
    state => state.account
  );

  const { fileUploadStatus } = useSelector(state => state.notification);

  const { selectOptionList } = useSelector(state => state.name);

  const { add_user_data } = accountInfo;

  const [availablePrefectures, setAvailablePrefectures] = useState(prefectures);

  const isUpdate = currentContents;

  const {
    accountInfo: { new_contents_flag, contents_condition_flag },
  } = useSelector(state => state.account);

  const initialValues = {
    start_post_period_date: null,
    start_post_period_hour: null,
    start_post_period_minutes: null,
    end_post_period_date: null,
    end_post_period_hour: null,
    end_post_period_minutes: null,
    display_order: null,
    title: '',
    sub_title: '',
    slider: '0',
    notice_flag: '0',
    cond_flag: '0',
    from_age: null,
    to_age: null,
    gender: '0',
    birth_month: null,
    prefectures_list: [],
    shop_list: [],
    file_type: '0',
    upload_file: null,
    link: null,
    banner_image_url: null,
    text: '',
    richText: toSlateState(currentContents?.richText),
    images: Array(9).fill(''),
    add_user_data_params: null,
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: Yup.object({
      start_post_period_date: Yup.string()
        .nullable()
        .test(
          'start_post_period_date',
          '日時を選択してください',
          (value, testContext) =>
            value &&
            testContext.parent.start_post_period_hour &&
            testContext.parent.start_post_period_minutes
        ),
      end_post_period_date: Yup.string()
        .nullable()
        .test(
          'end_post_period_date',
          '日時を選択してください',
          (value, testContext) =>
            value &&
            testContext.parent.end_post_period_hour &&
            testContext.parent.end_post_period_minutes
        ),
      title: Yup.string().required().max(50),
      sub_title: Yup.string().nullable().max(100),
      slider: Yup.string().required(),
      contents_type: Yup.string().nullable().required(),
      notice_flag: Yup.string().required(),
      banner_image_url: Yup.string().nullable().required(),
      upload_file_name: Yup.string()
        .nullable()
        .test(
          'upload_file_name',
          'ファイルを選択してください',
          (value, testContext) =>
            testContext.parent.cond_flag !== '2' ||
            !['0', '1'].includes(testContext.parent.file_type) ||
            value
        ),
    }),
    onSubmit: async values => {
      const {
        start_post_period_date,
        start_post_period_hour,
        start_post_period_minutes,
        end_post_period_date,
        end_post_period_hour,
        end_post_period_minutes,
        ...rest
      } = values;
      const startPostPeriod = moment(start_post_period_date)
        .set('h', Number(start_post_period_hour))
        .set('m', Number(start_post_period_minutes));
      const endPostPeriod = moment(values.end_post_period_date)
        .set('h', Number(end_post_period_hour))
        .set('m', Number(end_post_period_minutes));

      // 掲載期間前後チェック
      if (!startPostPeriod.isBefore(endPostPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message: '掲載開始日は掲載終了日より前の日付を入力してください',
          })
        );
        return;
      }

      const hasDetail =
        values?.richText?.length > 1 ||
        values?.richText.some(r =>
          (r.children ?? []).some(
            c =>
              c.text ||
              (c?.type === 'link' && (c.children ?? []).some(c => c.text))
          )
        );

      const params = {
        ...rest,
        start_post_period: startPostPeriod.format('YYYY-MM-DD HH:mm:ss'),
        end_post_period: endPostPeriod.format('YYYY-MM-DD HH:mm:ss'),
        rich_text: hasDetail
          ? values?.richText
            ?.map(t => serialize(t))
            ?.map((dom, i) => ({
              sort_num: `${i + 1}`,
              dom,
            }))
            .map(obj => obj.dom)
            .join('-----separator-----')
          : '',
        text:
          new_contents_flag !== 0 && hasDetail
            ? '%html%' +
            values?.richText
              ?.map(t => serialize(t))
              ?.map((dom, i) => ({
                sort_num: `${i + 1}`,
                dom,
              }))
              .map(obj => obj.dom)
              .join('<br />') +
            '%html%'
            : new_contents_flag !== 0
              ? ''
              : values?.text ?? '',
        prefectures_list: prefectures_list.map(p => p.value),
        shop_list: shop_list.map(s => s.shop_code),
        operator_code: accountInfo.staff_code,
      };
      dispatch(isUpdate ? contentsUpdate(params) : contentsCreate(params));
    },
  });

  const {
    banner_image_url,
    text,
    images,
    notice_flag,
    cond_flag,
    prefectures_list,
    shop_list,
    upload_file_name,
    file_type,
  } = formik.values;

  const setDisplayValues = () => {
    currentContents
      ? formik.setValues({
        ...currentContents,
        start_post_period_date: moment(
          currentContents?.start_post_period
        )?.format('YYYY/MM/DD'),
        start_post_period_hour: moment(
          currentContents?.start_post_period
        )?.format('HH'),
        start_post_period_minutes: moment(
          currentContents?.start_post_period
        )?.format('mm'),
        end_post_period_date: moment(
          currentContents?.end_post_period
        )?.format('YYYY/MM/DD'),
        end_post_period_hour: moment(
          currentContents?.end_post_period
        )?.format('HH'),
        end_post_period_minutes: moment(
          currentContents?.end_post_period
        )?.format('mm'),
        images: currentContents?.image_url || Array(9).fill(''),
        richText: toSlateState(currentContents?.richText),
        prefectures_list: (currentContents.prefectures_list ?? []).map(v => ({
          value: v,
          label: prefectures?.find(p => p?.value === v)?.label,
        })),
        shop_list: (currentContents.shop_list ?? []).map(s => ({
          shop_code: s,
          shop_name: accountShopList.find(v => v.value === s)?.label,
        })),
        gender: currentContents.gender ?? '0',
      })
      : formik.setValues({ ...initialValues });
  };

  useUpdateEffect(() => {
    setDisplayValues();
    setIsDetailOpen(
      currentContents?.text ||
      currentContents?.richText?.length ||
      currentContents?.image_url?.filter(i => i)?.length > 0
    );
  }, [currentContents]);

  useEffect(() => {
    if (!editModalVisible) {
      setIsDetailOpen(false);
      formik.resetForm();
      formik.validateForm();
    }
  }, [editModalVisible]);

  const onBack = () => {
    dispatch(contentsActions.closeEditModal());
    formik.resetForm();
  };

  const getImagesJsx = (from, to) => {
    return images.map((image, i) => {
      if (i < from || to < i) return <></>;
      return image ? (
        <div key={i}>
          <div className="uploadImageBox">
            <Image src={image} width={180} height={200} preview={false} />
            <Icon
              component={Icons.IconXCircleWh}
              className="close-icon"
              onClick={() => {
                let newImages = [...images];
                newImages[i] = '';
                handlerFormikFieldChange(formik, 'images', newImages);
              }}
            />
          </div>
          <div className="imageText">{'画像' + (i + 1)}</div>
        </div>
      ) : (
        <div key={i}>
          <div className="uploadImageBox">
            <BaseUpload
              showUploadList={false}
              onChange={url => {
                let newImages = [...images];
                newImages[i] = url;
                handlerFormikFieldChange(formik, 'images', newImages);
              }}
              style={{ margin: 5 }}
            >
              <Button
                text="画像を追加"
                theme="white"
                style={{
                  width: 160,
                  position: 'absolute',
                  top: 140,
                }}
              />
            </BaseUpload>
          </div>
          <div className="imageText">{'画像' + (i + 1)}</div>
        </div>
      );
    });
  };

  return (
    <Modal
      className={'contents-modal tc-modal'}
      open={editModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={currentContents ? 'コンテンツ編集' : 'コンテンツ登録'}
          tip={'*は必須項目です'}
        />
        <div className="input-area">
          <DateTimeRangePicker
            formik={formik}
            preLabel={'掲載期間'}
            fromFieldName="start_post_period_date"
            fromHourFieldName="start_post_period_hour"
            fromMinuteFieldName="start_post_period_minutes"
            toFieldName="end_post_period_date"
            toHourFieldName="end_post_period_hour"
            toMinuteFieldName="end_post_period_minutes"
            isRequired
            width={'100%'}
          />
          <SelectBox
            formik={formik}
            preLabel="表示優先順"
            fieldName="display_order"
            options={displayOrderOptions}
            placeholder={'00'}
            width={274}
          />
          <SelectBox
            formik={formik}
            preLabel="コンテンツ種別"
            fieldName="contents_type"
            options={
              selectOptionList?.filter(
                s => s.field_name === 'コンテンツ種別'
              )?.[0]?.select_option ?? []
            }
            isRequired
            width={380}
          />
          <TextBox
            formik={formik}
            preLabel="タイトル"
            fieldName="title"
            width={'100%'}
            isRequired
          />
          <TextBox
            formik={formik}
            preLabel="サブタイトル"
            fieldName="sub_title"
            width={'100%'}
          />
          <div className="check-area">
            <span className={'pre-label label-required'}>スライダー</span>
            <TcRadio
              options={selectOptionList?.filter(s => s.field_name === 'スライダー')?.[0]
                ?.select_option ?? []}
              formik={formik}
              fieldName={'slider'}
              width={210}
              onChange={v => handlerFormikFieldChange(formik, 'slider', v)}
              isRadio={true}
            />
          </div>
          {!currentContents?.send_status ||
            canUpdateSendStatus.includes(currentContents?.send_status) ? (
            <div className="check-area">
              <span className={'pre-label label-required'}>
                プッシュ通知配信
              </span>
              <TcRadio
                options={flagOptions}
                formik={formik}
                fieldName={'notice_flag'}
                width={120}
                onChange={v =>
                  handlerFormikFieldChange(formik, 'notice_flag', v)
                }
                isRadio={true}
              />
              <div className="push-detail">
                掲載開始日が過去日付の場合、プッシュ通知は配信されません。
              </div>
            </div>
          ) : (
            <div className="check-area">
              <span className={'pre-label'}>プッシュ通知配信</span>
              {flagOptions?.filter(e => e?.value === notice_flag)?.[0]?.label}
            </div>
          )}

          {contents_condition_flag === 1 ? (
            <>
              <span className="cus-input-line input-checkbox">
                <TcRadio
                  options={condOptions}
                  formik={formik}
                  label={'配信条件'}
                  width={240}
                  fieldName={'cond_flag'}
                  cancelable={false}
                  isRadio={true}
                  onChange={v => {
                    handlerFormikFieldChange(formik, 'cond_flag', v);
                    handlerFormikFieldChange(formik, 'upload_file', null);
                    handlerFormikFieldChange(formik, 'upload_file_name', null);
                    dispatch(notificationActions.clearFileUploadStatus());
                  }}
                />
              </span>
              {cond_flag === '1' ? (
                <>
                  <span className="cus-input-line input-age">
                    <CustomNumberPicker
                      preLabel="年齢"
                      formik={formik}
                      fieldName="from_age"
                      from={1}
                      to={120}
                      placeholder={'00'}
                      width={302}
                      suffix={'〜'}
                    />
                    <CustomNumberPicker
                      formik={formik}
                      fieldName="to_age"
                      from={1}
                      to={120}
                      width={150}
                      placeholder="00"
                      style={{ marginLeft: 10 }}
                    />
                  </span>
                  <span className="cus-input-line input-checkbox">
                    <TcRadio
                      options={genderOptions2}
                      formik={formik}
                      label={'性別'}
                      width={230}
                      fieldName={'gender'}
                      cancelable={false}
                      isRadio={true}
                    />
                  </span>
                  <SelectBox
                    preLabel="誕生月"
                    formik={formik}
                    fieldName="birth_month"
                    options={monthOptions}
                    placeholder={'00'}
                    width={302}
                    suffix={'月'}
                  />
                  <div className="check-area">
                    <span className={'cus-pre-label'}>都道府県</span>
                    <PrefecturesDualListBox
                      selectBoxId="notification_prefectures_select_box"
                      selectPrefectures={prefectures_list}
                      setSelectPrefectures={v =>
                        handlerFormikFieldChange(formik, 'prefectures_list', v)
                      }
                      availablePrefectures={availablePrefectures}
                      setAvailablePrefectures={setAvailablePrefectures}
                      disabled={false}
                    />
                  </div>
                  <div className="check-area">
                    <span className={'cus-pre-label'}>お気に入り店舗</span>
                    <DualListBox
                      selectBoxId="shop_select_box"
                      brandList={accountBrandList}
                      shopList={accountShopList}
                      selectShops={shop_list}
                      setSelectShops={v =>
                        handlerFormikFieldChange(formik, 'shop_list', v)
                      }
                      availableShops={availableShops}
                      setAvailableShops={setAvailableShops}
                      disabled={false}
                    />
                  </div>
                  {(add_user_data ?? []).map(a => {
                    switch (a.filter_type) {
                      case '0':
                        return (
                          <TextBox
                            formik={formik}
                            preLabel={a.name}
                            fieldName={`add_user_data_params.${a.field_name}`}
                            width={'100%'}
                          />
                        );
                      case '1':
                      case '2':
                        return (
                          <SelectBox
                            formik={formik}
                            preLabel={a.name}
                            fieldName={`add_user_data_params.${a.field_name}`}
                            mode={a.filter_type === '2' ? 'multiple' : null}
                            options={(a.filter_list ?? []).map(l => ({
                              label: l.list_name,
                              value: l.list_code,
                            }))}
                          />
                        );
                      case '3':
                        return (
                          <span className="cus-input-line input-age">
                            <CustomNumberPicker
                              preLabel={a.name}
                              formik={formik}
                              fieldName={`add_user_data_params.${a.field_name}_start`}
                              placeholder={'0'}
                              width={302}
                              suffix={'〜'}
                              from={a.filter_range?.start ?? 0}
                              to={a.filter_range?.end ?? 0}
                            />
                            <CustomNumberPicker
                              formik={formik}
                              fieldName={`add_user_data_params.${a.field_name}_end`}
                              width={150}
                              placeholder="0"
                              style={{ marginLeft: 10 }}
                              from={a.filter_range?.start ?? 0}
                              to={a.filter_range?.end ?? 0}
                            />
                          </span>
                        );
                      case '4':
                        return (
                          <DateTimeRangePicker
                            formik={formik}
                            preLabel={a.name}
                            fromFieldName={`add_user_data_params.${a.field_name}_start`}
                            toFieldName={`add_user_data_params.${a.field_name}_end`}
                            maxWidth={150}
                            width={462}
                          />
                        );
                      default:
                        return null;
                    }
                  })}
                </>
              ) : (
                cond_flag === '2' && (
                  <div className="cus-input-line input-file">
                    <span className={'cus-pre-label label-required'}>IDリスト</span>
                    <div>
                      <span className="input-file-label">ファイル区分</span>
                      <div style={{ display: 'flex' }}>
                        <TcRadio
                          isRadio={true}
                          options={[{ label: '端末ID', value: '0' }]}
                          formik={formik}
                          width={80}
                          fieldName={'file_type'}
                          onChange={() => {
                            handlerFormikFieldChange(
                              formik,
                              'upload_file',
                              null
                            );
                            handlerFormikFieldChange(
                              formik,
                              'upload_file_name',
                              null
                            );
                            dispatch(
                              notificationActions.clearFileUploadStatus()
                            );
                            handlerFormikFieldChange(formik, 'file_type', '0');
                          }}
                        />
                        <TcRadio
                          isRadio={true}
                          options={[{ label: '会員番号', value: '1' }]}
                          formik={formik}
                          width={80}
                          fieldName={'file_type'}
                          onChange={() => {
                            handlerFormikFieldChange(
                              formik,
                              'upload_file',
                              null
                            );
                            handlerFormikFieldChange(
                              formik,
                              'upload_file_name',
                              null
                            );
                            dispatch(
                              notificationActions.clearFileUploadStatus()
                            );
                            handlerFormikFieldChange(formik, 'file_type', '1');
                          }}
                        />
                      </div>
                      <div
                        className="file-area"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <BaseFileUpload
                          onChange={v => {
                            handlerFormikFieldChange(
                              formik,
                              'upload_file',
                              v.file
                            );
                            handlerFormikFieldChange(
                              formik,
                              'upload_file_name',
                              v.file.name
                            );
                            dispatch(
                              fetchNotificationReserveFileCheck({
                                operator_code: accountInfo.staff_code,
                                target_list_type: file_type,
                                upload_file: v.file,
                              })
                            );
                          }}
                          maxLine={90000}
                        >
                          <TcButton
                            text="ファイル選択"
                            theme="white"
                            style={{ marginLeft: 0 }}
                            icon={<Icons.IconClip />}
                          />
                        </BaseFileUpload>
                        <div
                          style={{
                            marginLeft: 10,
                            maxWidth: 300,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          ファイル名：
                          {upload_file_name ? upload_file_name : '-'}
                        </div>
                        {fileUploadStatus && (
                          <div style={{ marginLeft: 20 }}>
                            ファイル取込成功件数：
                            {fileUploadStatus?.success_count} /{' '}
                            {fileUploadStatus?.all_count}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              )}
            </>
          ) : (
            <></>
          )}

          <TextBox
            formik={formik}
            preLabel="外部リンク"
            fieldName="link"
            width={'100%'}
          />
          <div className="image-area">
            <span className={'pre-label label-required'}>バナー画像</span>
            <div className="image-select">
              {banner_image_url && (
                <Icon
                  component={Icons.IconXCircleWh}
                  className="close-icon"
                  onClick={e => {
                    handlerFormikFieldChange(formik, 'banner_image_url', null);
                    e.stopPropagation();
                  }}
                />
              )}
              {banner_image_url ? (
                <Image
                  src={banner_image_url}
                  width={150}
                  height={200}
                  preview={false}
                />
              ) : (
                <>
                  <BaseUpload
                    onChange={url =>
                      handlerFormikFieldChange(formik, 'banner_image_url', url)
                    }
                    onClick={() =>
                      formik.setFieldTouched('banner_image_url', true)
                    }
                  >
                    <Button text="画像を追加" theme="white" />
                  </BaseUpload>
                </>
              )}
            </div>
          </div>
          <div
            className={classNames({
              'detail-area': true,
            })}
          >
            {(currentContents?.richText?.length ||
              !isUpdate ||
              !currentContents?.text) &&
              new_contents_flag !== 0 ? (
              <>
                <label className="cus-pre-label">本文</label>

                <RichTextEditor
                  // articleCode={article_code}
                  articleCode={''}
                  contentImageBlock={true}
                  extraUpload={true}
                  editorState={formik.values?.richText}
                  // readOnly={!contentEditable}
                  readOnly={false}
                  error={formik.touched.richText && formik.errors.richText}
                  onChange={state => formik.setFieldValue('richText', state)}
                  setTouched={() =>
                    !formik.touched.richText &&
                    formik.setFieldTouched('richText', true)
                  }
                  // userCode={user_code}
                  userCode={'user_code'}
                />
              </>
            ) : (
              <>
                <span className="cus-input-line input-textarea">
                  <label className="cus-pre-label">
                    本文
                    <ContentsTooltip />
                  </label>
                  <textarea
                    placeholder="本文を入力してください"
                    spellCheck={false}
                    autoComplete={'false'}
                    value={text}
                    onChange={e =>
                      handlerFormikFieldChange(formik, 'text', e.target.value)
                    }
                  />

                  <Button
                    text={'プレビュー'}
                    onClick={() => {
                      dispatch(
                        globalActions.openPreviewModal({
                          // text,
                          text: formik.values?.text ?? '',
                          images: [banner_image_url, ...images],
                        })
                      );
                    }}
                  />
                </span>
                <div className="image-area">
                  <label>画像</label>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <div className="uploadImageRow" style={{ marginBottom: 5 }}>
                      {getImagesJsx(0, 2)}
                    </div>
                    <div className="uploadImageRow" style={{ marginBottom: 5 }}>
                      {getImagesJsx(3, 5)}
                    </div>
                    <div className="uploadImageRow">{getImagesJsx(6, 8)}</div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="button-container">
            <Button
              text="戻る"
              theme="white"
              onClick={() => dispatch(contentsActions.closeEditModal())}
            />
            <Button
              text={currentContents ? '更新' : '登録'}
              onClick={formik.handleSubmit}
              disabled={
                !formik.isValid ||
                (['0', '1'].includes(file_type) &&
                  fileUploadStatus?.success_count === 0)
              }
            />
          </div>
        </div>
      </div>
      <PreviewModal />
    </Modal>
  );
};

export default ContentsModal;
