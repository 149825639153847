import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
} from 'react-router-dom';
import PrivateRoute from './components/privateRoute';
import store from './slices/store';
import Login from './pages/login';
import Top from './pages/top';
import TopImage from './pages/topImage';
import Contents from './pages/contents';
import Coupon from './pages/coupon';
import CouponResult from './pages/couponResult';
import CouponUserResult from './pages/couponUserResult';
import Download from './pages/download';
import ActiveUser from './pages/activeUser';
import Heatmap from './pages/heatmap';
import PasswordRecovery from './pages/passwordRecovery';
import GlobalModal from './components/globalModal';
import FileUploadModal from './components/fileUploadModal';
import ProtectedRoute from './components/protectedRoute';
import UserManagement from './pages/userManagement';
import BrandManagement from './pages/brandManagement';
import ShopManagement from './pages/shopManagement';
import Stamp from './pages/stamp';
import QR from './pages/qr';
import StampedDetail from './pages/stampedDetail';
import MountUserResult from './pages/mountUserResult';
import StaffManagement from './pages/staffManagement';
import Privacy from './pages/privacy';
import Notification from './pages/notification';
import Reserve from './pages/reserve';
import Loading from './components/loading';
import Category from './pages/category';
import Enquete from './pages/enquete';
import StampRally from './pages/stampRally';
import StampRallyUserResult from './pages/stampRallyUserResult';
import StampRallyResult from './pages/stampRallyResult';
import PointExchangeResult from './pages/pointExchangeResult';
import Lottery from './pages/lottery';
import LotteryApplicant from './pages/lotteryApplicant';
import Gacha from './pages/gacha';
import GachaApplicant from './pages/gachaApplicant';
import Geopush from './pages/geopush';
import LineRichmenu from './pages/lineRichmenu';
import LineAccount from './pages/lineAccount';
import Template from "./pages/template";
import Name from './pages/name';
import AdventCalendar from "./pages/adventCalendar";

function App() {
  const privateRouters = [
    { path: '/top', element: <Top /> },
    { path: '/topImage', element: <TopImage /> },
    { path: '/contents', element: <Contents /> },
    { path: '/notification', element: <Notification /> },
    { path: '/reserve', element: <Reserve /> },
    { path: '/coupon', element: <Coupon /> },
    { path: '/couponResult', element: <CouponResult /> },
    { path: '/stamp', element: <Stamp /> },
    { path: '/qr', element: <QR /> },
    { path: '/mountUserResult', element: <MountUserResult /> },
    { path: '/stampedDetail', element: <StampedDetail /> },
    { path: '/couponUserResult', element: <CouponUserResult /> },
    { path: '/download', element: <Download /> },
    { path: '/activeUser', element: <ActiveUser /> },
    { path: '/heatmap', element: <Heatmap /> },
    { path: '/userManagement', element: <UserManagement /> },
    { path: '/brandManagement', element: <BrandManagement /> },
    { path: '/shopManagement', element: <ShopManagement /> },
    { path: '/staffManagement', element: <StaffManagement /> },
    { path: '/privacy', element: <Privacy /> },
    { path: '/enquete', element: <Enquete /> },
    { path: '/category', element: <Category /> },
    { path: '/lottery', element: <Lottery /> },
    { path: '/lotteryApplicant', element: <LotteryApplicant /> },
    { path: '/stampRally', element: <StampRally /> },
    { path: '/stampRallyUserResult', element: <StampRallyUserResult /> },
    { path: '/stampRallyResult', element: <StampRallyResult /> },
    { path: '/pointExchangeResult', element: <PointExchangeResult /> },
    { path: '/gacha', element: <Gacha /> },
    { path: '/gachaApplicant', element: <GachaApplicant /> },
    { path: '/geopush', element: <Geopush /> },
    { path: '/lineRichmenu', element: <LineRichmenu /> },
    { path: '/lineAccount', element: <LineAccount /> },
    { path: '/template', element: <Template /> },
    { path: '/name', element: <Name /> },
    { path: '/adventCalendar', element: <AdventCalendar /> },
  ];
  return (
    <Provider store={store}>
      <Loading />
      <GlobalModal />
      <FileUploadModal />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/passwordRecovery" element={<PasswordRecovery />} />
          <Route exact path="/" element={<ProtectedRoute />}>
            {privateRouters.map(({ path, element }) => (
              <Route
                exact
                path={path}
                element={<PrivateRoute exact path={path} element={element} />}
              >
                <Route exact path={path} element={element} />
              </Route>
            ))}
          </Route>
          <Route path="*" element={<Login />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
