import { Modal, Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { stampActions } from '../../slices/stampSlice';
import { stampRuleOptions } from '../../constants/options';
import ReferenceItem from '../referenceItem';
import ModalTitle from '../modalTitle';
import TcButton from '../tcButton';
import noimage from '../../images/noimage.png';
import { toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import './style.scss';
import ImagesReference from "../imagesReference";
import React from "react";

/* クーポン参照モーダル */

const StampReferenceModal = () => {
  const dispatch = useDispatch();

  const {
    accountShopList,
    accountInfo: { new_contents_flag },
  } = useSelector(state => state.account);

  const { stampReferenceModalVisible, stampReference } = useSelector(
    state => state.stamp
  );

  return (
    <Modal
      className={'stamp-modal tc-modal'}
      open={stampReferenceModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={() => dispatch(stampActions.resetStampModal())}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle title={'台紙参照'} />
        <div className="input-area">
          <div className="image-area">
            <span className={'pre-label'}>バナー画像</span>
            <div className="image-border">
              <Image
                src={stampReference?.stamp_image_url || noimage}
                width={150}
                height={150}
                preview={false}
              />
            </div>
          </div>
          <ReferenceItem label="台紙名" value={stampReference?.stamp_name} />
          {new_contents_flag !== 0 ? (
            <div className="text-area">
              <span className="pre-label editor-detail-title">台紙説明</span>
              <span className="editor-detail">
                <RichTextEditor
                  readOnly={true}
                  editorState={toSlateState(stampReference?.stamp_rich_text)}
                />
              </span>
            </div>
          ) : (
            <ReferenceItem
              label="台紙説明"
              value={stampReference?.stamp_text}
            />
          )}
          <div className="reference-image-area">
            <label className="text-required">画像</label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '80%',
              }}
            >
              <ImagesReference
                image_url={stampReference?.image_url}
                from={0}
                to={4}
                width={150}
                height={150}
              />
              <ImagesReference
                image_url={stampReference?.image_url}
                from={5}
                to={9}
                width={150}
                height={150}
              />
            </div>
          </div>
          <ReferenceItem
            label="表示期間"
            multiValue={[
              stampReference?.use_date_from,
              ` ${stampReference?.use_hour_from}:`,
              `${stampReference?.use_minute_from}　〜　`,
              stampReference?.use_date_to,
              ` ${stampReference?.use_hour_to}:`,
              `${stampReference?.use_minute_to}`,
            ]}
            joinString=""
          />
          <ReferenceItem
            label="有効期限"
            value={`${
              {
                0: '最新台紙初回押印日',
                1: '最新台紙最終押印日',
              }[stampReference?.valid_days_flag]
            }から ${stampReference?.valid_days ?? ''} 日`}
          />
          <ReferenceItem
            label="スタンプ数"
            value={`${stampReference?.stamp_count} 個`}
          />
          <ReferenceItem
            label="押印ルール"
            value={
              stampRuleOptions.find(r => r.value === stampReference?.stamp_rule)
                ?.label
            }
          />
          <ReferenceItem
            label="対象店舗"
            multiValue={(stampReference?.shop_list ?? []).map(
              s => accountShopList.find(shop => shop.value === s)?.label
            )}
            joinString=","
          />
          <ReferenceItem
            label="クーポン"
            value={`${stampReference?.coupon_code ?? ''}\t${
              stampReference?.coupon_name ?? ''
            }`}
          />
          <ReferenceItem label="定型分" value={stampReference?.fixed_phrase} />
          <div className="button-container">
            <TcButton
              text={'閉じる'}
              theme="white"
              onClick={() => dispatch(stampActions.resetStampModal())}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StampReferenceModal;
